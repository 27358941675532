
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid var(--secondary-80);
  background: var(--secondary-100);
  padding: 16px;
  transition: .3s;
  width: 100%;
  max-width: 664px;
  cursor: pointer;

  &:hover {
    background: var(--primary-90);

    .left {
      .icon {
        background: var(--primary-70);
      }
    }
  }

  .left {
    display: flex;
    gap: 24px;
    align-items: center;
    text-align: left;

    @include mobile {
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
    }

    .icon {
      transition: .3s;
      width: 50px;
      height: 50px;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--primary-80);

      @include mobile {
        width: 40px;
        height: 40px;
      }

      svg {
        color: var(--neutral-20);
      }
    }

    .text_wrapper {
      display: flex;
      flex-direction: column;
      gap: 2px;
      width: calc(100% - 60px);

      .title {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;

        @include mobile {
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
        }
      }

      .description {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        max-width: 411px;

        @include mobile {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
      }
    }
  }

  &.isChecked {
    background: var(--primary-90);
    border: 1px solid var(--primary-50);

    .left {
      .icon {
        background: var(--primary-70);
      }
    }
  }
}
