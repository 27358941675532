
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  justify-content: center;
  width: 100%;

  .content_wrapper {
    min-height: calc(100vh - 88px);
    height: max-content;
    width: 0;
    padding: 72px 32px 40px 32px;

    @include tablet {
      padding: 32px;
    }

    @include mobile {
      padding: 24px 16px;
    }

    &.isOpen {
      margin: 0 auto;
      width: 1475px;

      @include tablet {
        width: 100%;
      }
    }

    .content {
      width: 100%;
    }
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1475px;
    margin: 0 auto;
    padding: 72px 32px;
    overflow: hidden;

    @include tablet {
      padding: 32px;
    }

    @include mobile {
      padding: 24px 16px;
    }

    .burger {
      color: var(--secondary-20);
      display: flex;
      flex-direction: column;
      gap: 3.5px;
      width: 30px;
      height: 30px;
      align-items: center;
      justify-content: center;
      transition: .3s;

      & > div {
        transition: .3s;
        width: 24px;
        height: 2.5px;
        background: var(--secondary-20);
        opacity: 1;
      }

      &.isOpen {
        gap: 0;
        & > div {
          &:first-child {
            transform: rotate(-45deg);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:last-child {
            margin-top: -5px;
            transform: rotate(45deg);
          }
        }
      }

    }

    .title_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      &.isOpen {
        justify-content: space-between;
      }
    }

    .header_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      position: relative;
      padding-bottom: 24px;

      @include mobile {
        padding-bottom: 16px;
      }

      .back {
        position: absolute;
        left: 0;
      }
    }

    .text_wrapper {
      display: flex;
      flex-direction: column;
      max-width: 464px;
    }

    &.isOpen {
      width: 0;
      padding: 0;
      margin: 0;
      overflow-x: hidden;
    }

    &.isTabletOpen {
    }

    .divider {
      height: 1px;
      width: 100%;
      background: var(--secondary-80);
      margin-bottom: 40px;

      @include tablet {
        margin-bottom: 24px;
      }
    }

    .tab {
      display: flex;
      align-items: center;
      padding: 16px;
      gap: 24px;
      font-size: 18px;
      font-weight: 500;
      line-height: 150%;
      transition: .3s;
      cursor: pointer;
      width: 100%;
      position: relative;
      max-width: 664px;
      border-radius: 8px;
      border: 1px solid var(--secondary-80);
      background: var(--secondary-100);
      margin-bottom: 16px;

      .title {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        margin-bottom: 4px;
      }

      .description {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }

      @include tablet {
        gap: 16px;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
      }

      button {
        width: 48px;
        height: 48px;
      }

      .icon {
        position: absolute;
        right: 16px;
      }

      &:hover, &.isActive {
        background: var(--primary-90);

        & > button {
          background: var(--primary-70);
        }
      }
    }
  }
}