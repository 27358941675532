
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 16px;
  padding: 32px;

  .content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 16px;

    @include mobile {
      flex-direction: column;
    }
  }

  .row {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    gap: 16px;
    width: 300px;

    .photo_button {
      position: absolute;
      bottom: 16px;
    }

    @include mobile {
      padding: 0 16px;
      width: 100%;
      max-width: 420px;
    }
  }


  .loading_wrapper {
    $dim: 100px;
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 32px;

    .spinner {
      height: $dim;
      width: $dim;
      -webkit-animation: rotation 1s infinite linear;
      -moz-animation: rotation 1s infinite linear;
      -o-animation: rotation 1s infinite linear;
      animation: rotation 1s infinite linear;
      border: 6px solid var(--primary-50);
      border-radius: 100%;
    }

    .spinner:before {
      content: "";
      display: block;
      position: absolute;
      left: -6px;
      top: -6px;
      height: 100%;
      width: 100%;
      border-top: 6px solid var(--primary-30);
      border-left: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-right: 6px solid transparent;
      border-radius: 100%;
    }

    @-webkit-keyframes rotation {
      from {
        -webkit-transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(359deg);
      }
    }
    @-moz-keyframes rotation {
      from {
        -moz-transform: rotate(0deg);
      }
      to {
        -moz-transform: rotate(359deg);
      }
    }
    @-o-keyframes rotation {
      from {
        -o-transform: rotate(0deg);
      }
      to {
        -o-transform: rotate(359deg);
      }
    }
    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
      }
    }
  }

  .errors_content {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: center;
  }

  .steps_wrapper {
    width: 100%;
    display: flex;
    max-width: 600px;
    flex-direction: column;
    gap: 16px;

    .step_wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 32px;

      .step_number {
        background: var(--primary-60);
        padding: 8px;
        border-radius: 100px;
        width: 40px;
        height: 40px;
      }

      .step_text {
        width: calc(100% - 50px);
        display: flex;
        flex-direction: column;
        gap: 4px;
        text-align: left;
      }
    }
  }
}
