
                    @import "src/styles/_mixins.scss";
                
.cashback_wrapper {
  border-radius: 10px;
  border: 1px solid var(--secondary-80);
  background: var(--secondary-100);
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 360px;
  min-width: 282px;
  max-width: 100%;
  transition: .3s;

  @include mobile {
    margin-top: 16px;
    padding: 16px;
    width: 100%;
  }

  &:hover {
    background: var(--primary-100);
  }

  .header {
    display: flex;
    align-items: center;
    gap: 16px;

    .icon {
      border-radius: 30px;
      background: var(--primary-80);
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .cashback_content {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .info {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  .link {
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    color: var(--secondary-20);
    text-decoration: none;
  }

  .footer_wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .isAvailable {
      align-items: center;
      flex-direction: row;
      gap: 16px;
    }
  }
}