
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 473px;
  height: 200px;

  .title {
    margin-bottom: 16px;
    padding: 8px 0 16px 0;
    border-bottom: 1px solid var(--secondary-80);
    width: 100%;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
  }
}
