
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;

  p {
    max-width: 451px;
    width: 100%;
    text-align: center;
  }

  img {
    margin-bottom: 32px;
  }

  .title {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    margin-bottom: 12px;
  }

  .description {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: var(--neutral-40);
    margin-bottom: 40px;
    max-width: 374px;
    width: 100%;
  }
}
