
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  max-width: 300px;
  gap: 16px;

  svg {
    color: var(--primary-30);
    margin-bottom: -8px;
  }

  .title {
    margin-bottom: 16px;
    margin-top: -8px;
  }
}
