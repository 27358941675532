
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  z-index: 3;
  width: 100%;

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;

    button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 16px;
      border-radius: 10px;
      gap: 24px;

      @include mobile {
        padding: 8px 16px;
      }

      .text_wrapper {
        display: flex;
        align-items: center;
        gap: 24px;

        span {
          width: 40px;
          height: 40px;
          border-radius: 100px;
          background: var(--primary-80);
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      &:hover {
        background: var(--primary-90);
      }
    }

  }
}
