
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  position: fixed;
  top: 104px;
  right: 0;
  background: var(--secondary-90);
  z-index: 20;
  padding: 0 24px;
  transition: .3s;
  display: flex;
  align-items: center;

  @media (max-width: 1457px) {
    top: 88px;
  }

  @media (max-width: 1038px) {
    top: 109px;
  }

  @media (max-width: 1023px) {
    top: 102px;
  }

  @media (max-width: 970px) {
    top: 72px;
  }

  @include mobile {
    padding: 0 16px;
    width: 100%;
    max-width: 100%;
  }

  .empty_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100% - 200px);

    p {
      max-width: 200px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin-top: 24px;
      text-align: center;
    }
  }

  transform: translateX(100%);

  &.isOpen {
    transform: translateX(0);
  }

  .details {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 70px);
    padding: 24px 16px 24px 0;
    max-width: 495px;
    position: relative;
    transition: .3s;
    background: var(--secondary-90);

    transform: translateX(100%);
    width: 0;
    overflow: hidden;

    .details_button {
      margin: 32px auto;
    }

    .header_icon {
      display: flex;
      align-items: center;
      gap: 12px;

      .details_title {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%;
      }

      .icon {
        border-radius: 20px;
        background: var(--Primary-P-70, #FFE9C0);
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    &.isOpen {
      transform: translateX(0);
      overflow-y: auto;
      width: 495px;
      padding: 24px 24px 24px 0;
      border-right: 1px solid var(--secondary-80);
      margin-right: 16px;

      @include tablet {
        width: 100%;
        max-width: 100%;
        z-index: 40;
        border-right: 0;
        padding: 24px 0;
        margin: 0;
      }
    }

    .title_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 16px;
      margin-top: 24px;
      border-bottom: 1px solid var(--secondary-80);
    }

    .actions_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    .notification_content {
      & > .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 12px 0;

        p {
          span {
            font-weight: 600;
          }
        }
      }

      .body {

        p {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 160%;
          margin-top: 24px;
        }

        .load_more_button {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-weight: 400;
          line-height: 120%;
        }
      }
    }

    .date {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      color: var(--neutral-50);
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    max-width: 495px;
    overflow-y: auto;
    height: calc(100vh - 88px);
    padding: 24px 0;
    position: relative;
    width: 450px;
    background: var(--secondary-90);

    @include tablet {
      overflow: hidden;
      max-width: 100%;
      transform: translateX(0);
      width: 100%;

      &.isDetailsOpen {
        width: 0;
        transform: translateX(100%);
      }
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 24px;
    }

    .actions_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .choose_all {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        color: var(--neutral-50);
      }

      .actions {
        display: flex;
        gap: 15px;
        align-items: center;
      }
    }
  }

  .close_button {
    position: absolute;
    top: 23px;
    right: 0;

    @media (max-width: 970px) {
      top: 8px;
    }
  }

  .notifications_wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    margin-top: 24px;

    .skeleton {
      width: 100%;
      height: 120px;
    }

    .notification_block {
      display: flex;
      flex-direction: column;

      .month {
        padding-bottom: 8px;
        width: 100%;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        color: var(--neutral-50)
      }

      .notification_wrapper {
        position: relative;
        display: flex;
        align-items: center;
        padding: 10px 8px;
        gap: 8px;

        &:not(:last-child) {
          border-bottom: 1px solid var(--secondary-80);
        }

        .icon {
          border-radius: 20px;
          background: var(--Primary-P-70, #FFE9C0);
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            width: 24px;
            height: 24px;
          }
        }

        .text_wrapper {
          display: flex;
          flex-direction: column;
          width: calc(100% - 100px);

          @include mobile {
            width: auto;
          }

          .title {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 160%;
          }

          .time {
            color: var(--neutral-60);
            margin-top: 2px;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
          }

          &.isRead {
            opacity: 0.5;
          }
        }
      }
    }
  }

  .notification_month_block {
    margin-bottom: 16px;
  }
}

.overlay {
  width: 100vw;
  position: absolute;
  right: 0;
  background: rgba(0,0,0,0.2);
  height: 100vh;
  top: 0;
  z-index: 10;

  @media (max-width: 1457px) {
    top: 88px;
  }

  @media (max-width: 1038px) {
    top: 109px;
  }

  @media (max-width: 1023px) {
    top: 102px;
  }

  @media (max-width: 970px) {
    top: 80px;
  }
}