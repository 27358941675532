
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .title {
    margin-bottom: 10px;
    text-align: center;
  }

  .steps {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: var(--primary-30);
    margin-bottom: 16px;
  }

  .description {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: var(--neutral-40);
    max-width: 440px;
    text-align: center;
    margin-top: 16px;

    span {
      color: var(--neutral-40);
      font-weight: 600;
    }
  }

  .resend_button {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    color: var(--primary-30);
    margin-top: 24px;
  }

  .resend_text {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    color: var(--neutral-60);
    margin-top: 24px;
  }

  .form {
    max-width: 440px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;

    & > p {
      margin-bottom: 14px;
      align-self: flex-start;
      text-align: center;
      width: 100%;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    margin-top: 40px;

    .back {
      color: var(--neutral-40);

      svg {
        color: var(--neutral-40);
      }
    }
  }
}
