
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    padding: 6px 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    border-radius: 20px;
    width: max-content;

    @include mobile {
        font-size: 10px;
    }

    &.canceled {
        color: var(--error-40);
        background: var(--error-90);
    }

    &.REDEEMED {
        color: var(--primary-30);
        background: var(--primary-80);
    }

    &.PAID {
        color: var(--success-40);
        background: var(--success-90);
    }

    &.READY {
        color: #1AA19B;
        background: #D7F9F7;
    }

    &.CONFIRMED {
        color: #0C91DA;
        background: #DDF2FD;
    }

    &.PENDING {
        color: #FF7B00;
        background: #FFE9D4;
    }
}
