
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  @include tablet {
    gap: 24px;
  }

  @include mobile {
    gap: 16px;
    align-items: flex-start;
  }

  .same {
    display: flex;
    align-items: center;

    @include mobile {
      margin-bottom: 16px;
    }
  }

  .content_block {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .title {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      padding-bottom: 8px;
      color: var(--neutral-40);
      border-bottom: 1px solid var(--secondary-80);
      width: 100%;
    }

    .form {
      display: flex;
      flex-direction: column;
      gap: 24px;

      @include mobile {
        gap: 16px;
      }

      .checkbox_label {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }
  }

  .column {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .row {
    width: 100%;
    display: flex;
    gap: 24px;

    @include mobile {
      flex-direction: column;
      gap: 16px;
    }
  }
}
