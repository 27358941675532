
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .steps {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: var(--primary-30);
    margin-bottom: 16px;
  }

  .title {
    margin-bottom: 40px;
    text-align: center;
  }

  .info_wrapper {
    margin-top: 16px;
  }

  .info {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    color: var(--secondary-20);
  }

  .form {
    max-width: 440px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .actions {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    margin-top: 40px;
  }
}
