
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  max-width: 1436px;
  padding: 72px 32px;
  margin: 0 auto;
  width: 100%;

  @include tablet {
    padding: 32px 16px;
  }

  @include mobile {
    padding: 32px 24px;
  }

  .progress_bar {
    width: 356px;
    @include mobile {
      display: none;
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--secondary-80);
    width: 100%;
    margin-bottom: 40px;

    .back {
      position: absolute;
      left: 0;
    }

    & > p {
      @include mobile {
        max-width: 212px;
        text-align: center;
      }
    }
  }

  .content_wrapper {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;

    @include tablet {
      flex-direction: column;
      gap: 30px;
      align-items: center;
    }

    .progress {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;

      @include tablet {
        position: relative;
      }
    }

    .body_wrapper {
      width: 100%;
      max-width: 640px;

      &.large {
        max-width: 728px;
      }
    }
  }

  .actions_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-top: 40px;
  }
}
