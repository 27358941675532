
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  background: #EEEDEC;
  padding: 6px 6px 6px 16px;
  width: max-content;

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
  }
  button {
    height: 18px;
  }
}