
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 420px;

  @include mobile {
    margin: 0 16px;
    width: calc(100% - 32px);
  }

  .title {
    padding-bottom: 16px;
    border-bottom: 1px solid var(--secondary-80);
    width: 100%;
    text-align: center;
  }

  .preview {
    max-width: 395px;
    border-radius: 100%;
    width: 100%;
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}