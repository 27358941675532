
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  width: 584px;
  display: flex;
  gap: 24px;
  flex-direction: column;
  margin: 0 auto;

  @include mobile {
    gap: 16px;
    width: 100%;
  }

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 30px 45px;

    img, span {
      border-radius: 10px;
      width: 96px;
      height: 96px;
    }

    span {
      border-radius: 10px;
      border: 1px solid var(--secondary-80);
      background: var(--secondary-90);
    }
  }

  .back {
    align-self: flex-start;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 16px;

    @include tablet {
      margin-top: 8px;
    }

    @include mobile {
      margin-top: 0;
    }

    p {
      text-decoration: underline;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
  }
}
