
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .steps {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: var(--primary-30);
    margin-bottom: 16px;
  }

  .radio_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: 4px;
    border: 1px solid var(--secondary-80);
    background: var(--Primary-P-90, #FFF8EC);
    padding: 16px;
    margin-bottom: 16px;

    @include mobile {
      margin-bottom: 8px;
    }

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
    }
  }

  .same_address {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    padding-left: 16px;
    margin-bottom: 32px;

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;

      b {
        color: var(--neutral-50);
        margin-right: 8px;
      }
    }
  }

  .title {
    margin-bottom: 12px;
    text-align: center;
  }

  .description {
    margin-bottom: 48px;
    text-align: center;
  }

  .form {
    max-width: 640px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8px;

    .input_wrapper {
      width: 100%;
      display: flex;
      gap: 24px;
      margin-bottom: 24px;
    }

    .street {
      margin-bottom: 24px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    margin-top: 40px;

    .back {
      color: var(--neutral-40);

      svg {
        color: var(--neutral-40);
      }
    }
  }
}
