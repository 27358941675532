
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  padding: 48px;
  width: 500px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 80vh;
  gap: 40px;

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;

    .icon {
      height: 40px;
      width: 40px;
      border-radius: 100px;
      background: var(--Primary-P-80, #FDF1DC);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .title {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    width: 100%;

    .user {
      position: relative;
      border-radius: 16px;
      border: 1px solid var(--secondary-80);
      background: var(--primary-100);
      padding: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .actions {
        position: absolute;
        top: 16px;
        right: 16px;
        z-index: 9999999;
      }

      .user_avatar {
        width: 56px;
        height: 56px;
        border-radius: 100px;
        background: var(--primary-80);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        margin-bottom: 12px;
      }

      .user_name {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        margin-bottom: 2px;
      }

      .user_email {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        margin-bottom: 2px;
      }

      .divider {
        width: 100%;
        height: 1px;
        background: var(--secondary-80);
        margin: 16px 0;
        max-width: 247px;
      }

      .payment_id {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 2px;
        width: 100%;
        margin-bottom: 9px;

        .id {
          display: flex;
          align-items: center;
          gap: 6px;
        }

        p {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
        }

        button {
          height: 18px;
        }
      }

      .details {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
        margin-top: 32px;

        .row {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          gap: 12px;

          & > div {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;
          }
        }

        .progress {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 12px;

          .progress_bg {
            border-radius: 40px;
            background: var(--primary-80);
            height: 8px;
            width: 100%;

            & > div {
              border-radius: 20px;
              background: var(--primary-40);
              height: 100%;
            }
          }

          .progress_title {
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
          }
        }
      }
    }

    .dates {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 100%;

      .row {
        display: flex;
        justify-content: space-between;
        gap: 16px;

        .date {
          display: flex;
          gap: 8px;

          .text_wrapper {
            display: flex;
            flex-direction: column;
            gap: 3px;

            p {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 160%;
              color: var(--neutral-40);
            }

            span {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 160%;
            }
          }
        }

        .dashed_divider {
          margin-top: 13px;
          width: 100%;
          height: 1px;
          border-top: 1px dashed var(--secondary-80);
        }
      }

      .divider {
        width: 100%;
        height: 1px;
        background: var(--secondary-80);
      }
    }

    .dot {
      width: 8px;
      height: 8px;
      border-radius: 100px;
      margin: 6px 6px;

      &.error {
        background: #DC362E;
      }

      &.warning {
        background: #FF7B00;
      }

      &.success {
        background: #1DAF68;
      }

      &.gray {
        background: var(--neutral-50);
      }
    }
  }
}