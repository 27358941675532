
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  max-width: 404px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &.full {
    max-width: 100%;
  }

  .drop_wrapper {
    width: 100%;
    border-radius: 4px;
    border: 1px dashed var(--secondary-80);
    background: var(--secondary-100);
    padding: 16px;
    display: flex;
    gap: 8px;

    .icon {
      color: var(--neutral-60);
    }

    .content_wrapper {
      width: calc(100% - 32px);
      display: flex;
      flex-direction: column;
      gap: 4px;

      .title {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%;

        span {
          color: var(--primary-30);
        }
      }

      .accept {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        color: var(--neutral-40);
      }
    }
  }

  .files_wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }

  .file {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px;
    border-radius: 6px;
    border: 1px solid var(--neutral-60);
    gap: 8px;
    justify-content: space-between;

    .file_icon {
      position: relative;
      .text {
        position: absolute;
        font-size: 8px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        color: white;
        padding: 2.4px 1.6px;
        background: var(--primary-40);
        border-radius: 1.6px;
        text-transform: uppercase;
        bottom: 10.2px;
        left: -3.2px;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
      }

      svg {
        color: var(--neutral-40);
      }
    }

    .delete {
      margin: 0;
      svg {
        color: var(--neutral-40);
      }
    }

    .title {
      display: flex;
      align-items: center;

      p {
        max-width: 200px;
        overflow: hidden;
        height: 23px;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%;
      }
      span {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        color: var(--neutral-40);
      }
    }

    .content {
      width: calc(100% - 80px);
      gap: 8px;
    }
  }
}