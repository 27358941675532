
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  transform: translateX(100%);
  width: 100vw;
  height: 100vh;
  transition: .3s;
  z-index: 10;

  &.isOpen {
    transform: translateX(0);
  }

  .drawer {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 800px;
    z-index: 50;
    background: white;
    height: 100vh;
    padding: 32px;

    @include tablet {
      max-width: 100%;
    }

    .header_actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
    }

    .header_wrapper {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: 16px;

      .left_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        width: 100%;

        .request_data {
          display: flex;
          align-items: center;
          gap: 16px;
        }
      }

      .date {
        color: var(--neutral-50)
      }
    }



  }

  .overlay {
    z-index: 40;
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
  }
}