
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  max-width: 1473px;
  margin: 0 auto;
  padding: 88px 32px;

  @include tablet {
    padding-top: 48px;
  }

  @include mobile {
    padding: 40px 24px;

    & > p {
      font-size: 20px !important;
    }
  }

  .actions_wrapper {
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    border-top: 1px solid var(--secondary-80);
    margin-top: 16px;

    .action {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-decoration: none;
      border-radius: 10px;
      transition: .3s;
      position: relative;
      height: 72px;
      padding: 16px;
      overflow: hidden;

      @include mobile{
        padding: 8px;
      }

      &:hover {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .25);
      }

      &:active {
        box-shadow: none;
      }

      & > div {
        display: flex;
        align-items: center;
        gap: 24px;

        span {
          width: 40px;
          height: 40px;
          border-radius: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: var(--primary-80);
        }
      }
    }
  }
}
