
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  max-width: 473px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  .title {
    margin-bottom: 8px;
    text-align: center;
  }

  .description {
    margin-bottom: 33px;
    text-align: center;

    @include mobile {
      margin-bottom: 24px;
    }
  }

  .input_wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;

    a {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      text-decoration: underline;
      color: var(--neutral-30);
      margin-right: 16px;
      margin-top: -4px;
    }

    @include mobile {
      gap: 0;
    }
  }
}
