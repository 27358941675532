
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .content {
    display: flex;
    flex-direction: column;
    padding: 16px 0;
  }

  .skeleton {
    height: 64px;
  }
}
