
                    @import "src/styles/_mixins.scss";
                
.root {
  position: relative;
  width: 46px;
  height: 46px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  transition: .3s;

  & > span {
    transition: .3s;
    border-radius: 100px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--secondary-30);

    i {
      width: 12px;
      height: 12px;
      border-radius: 100px;
    }
  }

  &.black {
    border-color: var(--secondary-10);
  }

  &.large {
    width: 56px;
    height: 56px;

    & > span {
      border-width: 3px;

      i {
        width: 17px;
        height: 17px;
      }
    }
  }

  &.small {
    width: 12px;
    height: 12px;

    & > span {
      border-width: 1px;

      i {
        width: 6px;
        height: 6px;
      }
    }
  }

  &:hover {
    background: rgba(219, 158, 47, 0.12);

    &.isChecked {
      background: var(--primary-80);
    }

    &.isDisabled {
      background: none;
    }
  }

  &.isChecked {
    & > span {
      border-color: var(--primary-30);

      i {
        background:var(--primary-30);
      }
    }

    &.black {
      & > span {
        border-color: var(--secondary-10);

        i {
          background:var(--secondary-10);
        }
      }
    }
  }

  input {
    display: none;
  }

  &.isDisabled {
    opacity: 0.38;

    span {
      border-color: var(--secondary-30);
    }

    &.isChecked {
      & > span {
        i {
          background: var(--secondary-30);
        }}
    }
  }
}
