
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;
  border-radius: 10px;

  .content {
    display: flex;
    gap: 24px;
    align-items: center;

    .card_image {
      width: 50px;
      height: 32px;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .text_wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      p {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        max-width: 380px;
        width: 100%;
      }

      & > span {
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
        color: var(--neutral-50);
      }
    }
  }

  &:hover {
    background: var(--primary-90);
  }
}
