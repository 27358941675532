
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  width: 100%;

  .input {
    padding-left: 10px !important;
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
}
