
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  max-width: 1473px;
  margin: 0 auto;
  padding: 84px 32px;
  width: 100%;

  .action {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  @include tablet {
    padding: 40px;
  }

  @include mobile {
    padding: 32px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    @include tablet {
      margin-bottom: 32px;
    }

    @include mobile {
      margin-bottom: 24px;
    }
  }

  .table_header {
    margin-bottom: 28px;

    @include tablet {
      margin-bottom: 20px;
    }

    @include mobile {
      margin-bottom: 16px;
    }
  }

  .filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    @include tablet {
      margin-bottom: 16px;
    }

    @include mobile {
      flex-direction: column;
      gap: 8px;
    }

    .search {
      max-width: 400px;

      @include mobile {
        max-width: 100%;
      }
    }

    .range {
      max-width: 300px;

      @include mobile {
        max-width: 100%;
      }
    }
  }

  .empty_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 32px;
    margin: 32px 0;
    p {
      max-width: 366px;
      text-align: center;
    }
  }

  .skeleton {
    width: 100%;
    height: 40px;
    margin-bottom: 12px;
  }
}
