
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  width: 452px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1457px) {
    width: 100%;
  }

  .title_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--secondary-80);

    svg {
      color: var(--primary-30);
    }
  }

  .block_wrapper {
    padding: 21px 0 21px 21px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .tags_wrapper {
      display: flex;
      gap: 19px;
      flex-wrap: wrap;

      button {
        font-weight: 500;
      }
    }
  }
}
