
                    @import "src/styles/_mixins.scss";
                
.root {
  position: relative;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  transition: .3s;

  & > span {
    transition: .3s;
    width: 18px;
    height: 18px;
    border-radius: 2px;

    & > svg {
      transition: .3s;
      position: absolute;
      left: 8px;
      top: 8px;
    }
  }

  .hover {
    position: absolute;
    width: 36px;
    height: 36px;
    background: none;
    transition: .3s;
    border-radius: 100px
  }

  &.isDisabled {
    opacity: 0.38;
  }

  &:hover {
    &.dark {
      .hover {
        background: rgba(219, 158, 47, 0.12);
      }
    }

    &.red {
      .hover {
        background: var(--error-90);
      }
    }

    &.isDisabled {
      .hover {
        background: none;
      }
    }
  }

  &.isChecked {
    &.dark {
      & > span {
        background: var(--secondary-30);

        & > svg {
          color: var(--neutral-100);
        }
      }
    }

    &.red {
      & > span {
        background: var(--error-40);

        & > svg {
          color: var(--neutral-100);
        }
      }
    }

    &.gold {
      & > span {
        background: var(--primary-30);
        border-color: var(--primary-30);

        & > svg {
          color: var(--neutral-100);
        }
      }
    }
  }

  &.small {
    width: 18px;
    height: 18px;
    border-radius: 3px;

    & > span {
      svg {
        top: 0;
        left: 0;
        width: 18px;
        height: 18px;
      }
    }
  }

  &.dark {
    & > span {
      border: 2px solid var(--secondary-30);
    }
  }

  &.red {
    & > span {
      border: 2px solid var(--error-40);
    }
  }

  &.gold {
    & > span {
      border: 2px solid var(--neutral-50);
    }
  }

  input {
    display: none;
  }
}
