
                    @import "src/styles/_mixins.scss";
                
.main_wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  width: 100%;

  @include tablet {
    gap: 24px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: 100%;

    & > .actions {
      display: flex;
      gap: 16px;
      align-items: center;
      justify-content: center;
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 40px;
      width: 100%;
      z-index: 1;

      @include tablet {
        gap: 24px;
      }
    }

    .cards_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
    }

    .form_row {
      display: flex;
      align-items: center;
      gap: 24px;
      width: 100%;

      .full_row {
        width: 100%;
      }

      .row {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 16px;
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--secondary-80);
    width: 100%;

    .back {
      position: absolute;
      left: 0;
    }

    & > p {
      @include mobile {
        max-width: 212px;
        text-align: center;
      }
    }
  }

  .content_wrapper {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;

    @include tablet {
      flex-direction: column;
      gap: 30px;
      align-items: center;
    }

    .progress {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;

      @include tablet {
        position: relative;
      }
    }

    .body_wrapper {
      width: 100%;
      max-width: 640px;
    }
  }
}