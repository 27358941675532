
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1400px;
  margin: 32px auto;
  padding: 0 14px;
  gap: 24px;
  margin-top: -40px;

  @include tablet {
    margin-top: 0;
  }

  @include mobile {
    margin-top: 0;
    width: 100%;
    padding: 0 16px;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .subtitle {
      text-align: center;
    }
  }
}