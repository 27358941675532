
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    background: var(--secondary-20);
    padding: 32px 0;
    position: relative;
    width: 100%;

    @media (max-width: 1457px) {
        padding: 24px 16px;
    }

    @include tablet {
        padding: 31px 39px;
    }

    @media (max-width: 970px) {
        padding: 16px 27px;
    }

    .container {
        width: 1425px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
            display: flex;
            align-items: flex-end;
            gap: 16px;
        }

        @media (max-width: 1457px) {
            width: 100%;
        }

        .navigation {
            ul {
                display: flex;
                align-items: center;
                gap: 24px;
            }

            @include tablet {
                ul {
                    gap: 24px;
                    li a {
                        font-size: 18px;
                    }
                }
            }

            @media (max-width: 970px) {
                display: none;
            }
        }

        .logo {
            img {
                @include tablet {
                    width: 100px;
                }
            }
        }

        .right {
            display: flex;
            align-items: center;
            gap: 18px;

            @include tablet {
                gap: 16px;
            }

            @media (max-width: 970px){
                gap: 8px;
            }

            .language,
            .profile_button {
                p {
                    text-transform: uppercase;
                    color: var(--primary-50);
                }
            }

            .language {
                color: var(--primary-100);

                p {
                    color: var(--primary-100);

                    &.isActive {
                        color: var(--primary-50);
                        font-weight: 700;
                    }
                }
            }

            .profile_button {
                svg {
                    color: var(--primary-50);
                }
                @include tablet {
                    p {
                        display: none;
                    }
                }
            }

            .notifications_wrapper {
                position: relative;
                width: max-content;
            }

            .notification,
            .logo_symbols {
                position: relative;
                padding: 8px;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100px;
                background: var(--primary-80);
                font-size: 16px;
                font-weight: 500;
                font-family: Inter, sans-serif;

                svg {
                    color: var(--neutral-30);
                }
            }

            .avatar {
                width: 40px;
                height: 40px;
                border-radius: 100px;
            }

            .profile_wrapper {
                position: relative;
                display: flex;
                align-items: center;
                gap: 18px;

                @include mobile {
                    gap: 0;
                }

                .avatar_wrapper {
                    cursor: pointer;
                    height: 40px;
                }

                .profile_button {
                    cursor: pointer;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    gap: 18px;

                    @include tablet {
                        p {
                            display: none;
                        }

                        svg {
                            display: none;
                        }
                    }
                }

                .profile_dropdown  {
                    border-radius: 10px;
                    background: var(--primary-100);
                    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    width: 370px;
                    right: 0;
                    top: 47px;
                    z-index: 10;
                    height: 0;
                    transition: .3s;
                    overflow: hidden;

                    .logo_symbols {
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        color: var(--neutral-30);

                        @include mobile {
                            width: 56px;
                            height: 56px;
                        }
                    }

                    .avatar {
                        margin-bottom: 16px;

                        @include mobile {
                            width: 56px;
                            height: 56px;
                        }
                    }

                    &.isOpen {
                        height: auto;
                        padding: 16px 0;

                        @include mobile {
                            top: 56px;
                            height: calc(100vh - 70px);
                            width: 100vw;
                            right: -27px;
                            border-radius: 0;
                            align-items: center;
                            padding: 72px 24px;
                        }
                    }

                    .avatar_wrapper {
                        display: none;
                        margin-bottom: 16px;

                        @include mobile {
                            display: block;
                        }

                        .avatar {
                            width: 56px;
                            height: 56px;
                        }
                    }

                    .divider {
                        background: var(--secondary-80);
                        height: 1px;
                        width: calc(100% - 48px);
                        margin: 8px 24px;

                        @include mobile {
                            margin: 16px 0;
                            width: 100%;

                            &.mobile_divider {
                                display: none;
                            }
                        }
                    }

                    .name {
                        text-transform: uppercase;
                        padding-left: 20px;
                        margin-bottom: 8px;

                        @include mobile {
                            padding-left: 0;
                            margin-bottom: 6px;
                            margin-top: 16px;
                        }
                    }

                    .email {
                        color: var(--neutral-40);
                        padding: 0 24px;

                        @include mobile {
                            padding: 0;
                            margin-bottom: 8px;
                        }
                    }

                    .account {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        button {
                            color: var(--primary-50);
                            border-radius: 5px;
                            background: var(--secondary-30);
                        }
                    }
                    .action {
                        text-decoration: none;
                        width: calc(100% - 32px);
                        justify-content: flex-start;
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        padding: 12px 8px;
                        margin: 0 16px;
                        height: 45px !important;
                        border-radius: 4px;

                        @include mobile {
                            width: 100%;
                            margin: 0;
                            padding: 16px 8px;
                        }

                        &:hover {
                            background: var(--primary-80);
                        }
                    }

                    .logout_link {
                        @include mobile {
                            position: absolute;
                            bottom: 40px;
                            left: 24px;
                        }
                    }

                    .logout {
                        color: var(--error-40)
                    }
                }
            }

        }

        .mobile_menu {
            display: none;

            .burger {
                color: var(--primary-50);
                display: flex;
                flex-direction: column;
                gap: 3.5px;
                width: 30px;
                height: 30px;
                align-items: center;
                justify-content: center;
                transition: .3s;

                & > div {
                    transition: .3s;
                    width: 24px;
                    height: 2.5px;
                    background: var(--primary-50);
                    opacity: 1;
                }

                &.isOpen {
                    gap: 0;
                    & > div {
                        &:first-child {
                            transform: rotate(-45deg);
                        }
                        &:nth-child(2) {
                            opacity: 0;
                        }
                        &:last-child {
                            margin-top: -5px;
                            transform: rotate(45deg);
                        }
                    }
                }

            }

            .dropdown {
                position: absolute;
                z-index: 10;
                background: var(--secondary-20);
                max-width: 324px;
                width: 100vw;
                left: -27px;
                top: 50px;
                display: flex;
                flex-direction: column;
                height: 0;
                padding: 0 40px;
                transition: .3s;
                overflow: hidden;

                &.isOpen {
                    padding: 40px;
                    height: calc(100vh - 70px);
                }

                .divider {
                    height: 1px;
                    width: calc(100% - 32px);
                    align-self: center;
                }

                .action {
                    padding: 16px 8px;

                    p {
                        color: white;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 150%;
                    }
                }

                .name {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    letter-spacing: 0.1px;
                    color: var(--secondary-100);
                    padding: 18px 24px;
                }
            }

            @media (max-width: 970px) {
                display: block;
                position: relative;

                & > div > div {
                    background: var(--primary-50) !important;
                }
            }
        }
    }
}

.kyc_wrapper {
    background: var(--primary-50);
    padding: 8px 32px;
    position: relative;

    @include mobile {
        padding: 8px 16px;
    }

    .container {
        width: 1425px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;

        @media (max-width: 1457px) {
            width: 100%;
        }

        @include mobile {
            flex-direction: column;
        }
    }
}
