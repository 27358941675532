
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;

  @include mobile {
    width: 100%;
    gap: 24px;
  }

  & > .description {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: center;
    max-width: 462px;
  }

  .card {
    padding: 24px;
    border-radius: 8px;
    border: 1px solid var(--secondary-80);
    background: var(--secondary-100);
    display: flex;
    justify-content: space-between;
    gap: 24px;
    transition: .3s;

    &.isActive {
      background: var(--primary-90);
    }

    .title {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
    }

    .description {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }

    .left {
      display: flex;
      flex-direction: column;
      gap: 4px;
      text-align: left;

      @include mobile {
        gap: 8px;
      }
    }

    &:first-child {
      .left {
        span {
          max-width: 408px;
        }
      }
    }

    &:last-child {
      .left {
        span {
          max-width: 472px;
        }
      }
    }
  }

  .back {
    align-self: flex-start;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 16px;

    @include tablet {
      margin-top: 8px;
    }

    @include mobile {
      margin-top: 0;
    }

    p {
      text-decoration: underline;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
  }
}
