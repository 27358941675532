
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include tablet {
    gap: 16px;
  }

  .title {
    span {
      font-weight: 400;
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 24px;

    @media (max-width: 1140px){
      flex-direction: column;
      width: 100%;
    }

    @include mobile {
      gap: 24px;
    }

    .left_content {
      position: relative;
      display: flex;
      align-items: center;
      gap: 96px;
      width: 100%;

      @include tablet {
        flex-direction: column;
        width: 100%;
        gap: 24px;
      }

      .agreement {
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .actions_wrapper {
      display: flex;
      align-items: stretch;
      gap: 32px;

      @include mobile {
        gap: 24px;
        width: 100%;
        justify-content: center;
      }

      .action {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        max-width: 110px;
        width: max-content;

        button {
          width: 48px;
          height: 48px;
        }

        &.second {
          button {
            background: var(--neutral-80);
            border: none;
          }
        }

        p {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%;
          text-align: center;
        }
      }
    }

    .cashback_wrapper {
      cursor: pointer;
      border-radius: 10px;
      border: 1px solid var(--secondary-80);
      background: var(--secondary-100);
      padding: 18px 11px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 312px;
      transition: .3s;

      &:hover {
        background: var(--primary-100);
      }

      .header {
        display: flex;
        align-items: center;
        gap: 16px;

        .icon {
          border-radius: 30px;
          background: var(--primary-80);
          width: 60px;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .cashback_content {
        display: flex;
        flex-direction: column;
        gap: 2px;

        .info {
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }
    }
  }

  .card_skeleton {
    width: 311px;
    height: 195px;
  }

  .card_details_wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .row {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .title {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        color: var(--neutral-40);
      }

      .value {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }
  }
}
