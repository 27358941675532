
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include mobile {
    gap: 24px;
  }

  .recurring_icon {
    position: absolute;
  }

  .text_wrapper {
    width: calc(100% - 64px);

    .title {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      margin-bottom: 3px;

      @include mobile {
        max-width: 205px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0;
      }
    }

    .description {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      gap: 8px;
      display: flex;

      @include mobile {
        display: flex;
        flex-direction: column;
        gap: 0;
      }

      i {
        color: var(--neutral-40);
        font-style: normal;
        margin: 0;
        padding: 0;

        @include mobile {
          font-size: 12px;
        }
      }

      br {
        display: none;

        @include mobile {
          display: inline;
        }
      }

      span {
        color: #666C75;
        font-size: 8px;

        @include mobile {
          display: none;
        }
      }
    }
  }

  .empty_transactions {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 24px 0;

    img {
      margin-bottom: 24px;
    }

    .title {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      margin-bottom: 12px;
    }

    .description {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
    }
  }

  .table_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    border: 1px solid var(--secondary-80);
    background: var(--secondary-100);
    padding: 16px;
    transition: .3s;

    @include mobile {
      gap: 8px;
      border: none;
      padding: 0 16px;
    }

    &:hover {
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
    }

    .right {
      display: flex;
      align-items: center;
      gap: 49px;

      @include mobile {
        flex-direction: column;
        align-items: flex-end;
        gap: 2px;
      }
    }

    .left {
      display: flex;
      gap: 24px;
      align-items: center;
      text-align: left;

      @include mobile {
        width: 100%;
        gap: 12px;
      }

      .icon {
        width: 50px;
        height: 50px;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--primary-80);
        position: relative;

        @include mobile {
          width: 40px;
          height: 40px;
        }

        &.isWallet {
          background: var(--primary-50);
        }

        svg {
          color: var(--neutral-20);
        }
      }
    }

    .amount {
      width: 120px;
      text-align: right;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;

      @include mobile {
        font-size: 16px;
        width: auto;
      }
      &.plus {
        color: var(--success-40);
      }
      &.isCancel {
        color: var(--error-40)
      }
    }

    .status {
      padding: 2px 19px;
      font-size: 14px;

      @include mobile {
        background: none;
        padding: 0;
      }
    }
  }

  .action {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .skeleton {
    height: 87px;
  }
}
