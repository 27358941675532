
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  max-width: 1434px;
  width: 100%;
  margin: 0 auto;
  padding: 41px 14px;
  display: flex;
  flex-direction: column;
  gap: 87px;

  @include tablet {
    padding: 32px 14px;
    gap: 40px;
  }

  @include mobile {
    padding: 32px 24px;
    gap: 54px;
  }
}
