
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .steps {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: var(--primary-30);
    margin-bottom: 16px;
  }

  .title {
    margin-bottom: 40px;
    text-align: center;

    @include mobile {
      margin-bottom: 32px;
    }
  }

  .row {
    width: 100%;
    display: flex;
    gap: 20px;

    @include mobile {
      flex-direction: column;
      gap: 16px;
    }
  }

  .form {
    max-width: 640px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    @include mobile {
      gap: 16px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    margin-top: 20px;

    @include mobile {
      margin-top: 12px;
    }

    .back {
      color: var(--neutral-40);

      svg {
        color: var(--neutral-40);
      }
    }
  }
}
