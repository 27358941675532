
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  width: 100%;
}

.dropZone {
  padding: 16px;
  width: 100%;
  height: 320px;
  border-radius: 10px;
  background: var(--primary-80);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;

  &.isDisabled {
    cursor: default;
  }
}

.dragOver {
  background-color: var(--primary-70);
}

.preview {
  max-width: 200px;
  max-height: 100%;
  border-radius: 10px;
}

.fileInput {
  display: none;
}