
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  max-width: 320px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    margin-bottom: 8px;
    text-align: center;
  }

  .description {
    margin-bottom: 24px;
    text-align: center;

    @include mobile {
      margin-bottom: 24px;
    }
  }
}
