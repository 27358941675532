
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  max-width: 960px;
  padding: 0 24px;
  display: flex;
  gap: 32px;
  width: 100%;

  @include mobile {
    flex-direction: column;
    gap: 24px;
  }

  .card {
    padding: 48px;
    border-radius: 8px;
    border: 1px solid var(--secondary-80);
    background: var(--secondary-100);
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 464px;
    width: 100%;

    img {
      max-width: 280px;
      width: 100%;
      margin-bottom: 24px;
    }

    .text_wrapper {
      margin-bottom: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .title {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      margin-bottom: 8px;
      text-align: center;
    }

    .description {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      max-width: 234px;
      text-align: center;
    }

  }
}
