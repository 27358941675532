
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;

    .content {
        display: flex;
        flex-direction: column;
        gap: 21px;

        .active_filters {
            display: flex;
            width: 100%;
            align-items: center;
            flex-wrap: wrap;
            gap: 16px;

            .clear_all {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 130%;
                height: max-content;
            }
        }
    }

    .table_header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .right {
            display: flex;
            align-items: center;
            gap: 16px;

            button {
                padding: 4px 24px;

                @include mobile {
                    width: 32px;
                    height: 32px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0;

                    p {
                        display: none;
                    }
                }
            }
        }
    }
}
