
                    @import "src/styles/_mixins.scss";
                
.main_wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: 1300px) {
    margin-left: 16px;
  }

  .table_actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 4px;

    .delete {
      svg {
        color: var(--error-40)
      }
    }
  }

  .empty {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding-top: 32px;

    img {
      width: 200px;
      margin-bottom: 32px;
    }

    .title {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      margin-bottom: 12px;
      max-width: 234px;
      text-align: center;
    }

    .description {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      color: var(--neutral-40);
      margin-bottom: 40px;
      max-width: 308px;
      text-align: center;
    }
  }

  .search_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16px;

    .search {
      max-width: 480px;
    }

    @include mobile {
      flex-direction: column-reverse;
      gap: 16px;

      .search {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: 100%;

    & > .actions {
      display: flex;
      gap: 16px;
      align-items: center;
      justify-content: center;
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 100%;
      z-index: 1;

      .header {
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;
        padding: 16px 0;
        border-bottom: 1px solid var(--secondary-80);
        margin-bottom: 40px;

        @include tablet {
          margin-bottom: 32px;
        }

        .back {
          position: absolute;
          left: 0;
        }
      }

      .actions {
        display: flex;
        align-items: center;
        gap: 10px;

        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        @include tablet {
          gap: 2px;
        }
      }
    }

    .form_row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
      margin-bottom: 8px;

      .date {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      .row {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 24px;
      }
    }
  }
}