
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;
  border-radius: 10px;

  .content {
    display: flex;
    gap: 24px;
    align-items: center;

    & > svg {
      width: 40px;
      height: 40px;
      padding: 8px;
      border-radius: 100px;
      background: var(--secondary-30);
      color: var(--primary-50);
    }

    .text_wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      p {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
      }
    }
  }

  &:hover {
    background: var(--primary-90);
  }
}
