
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  max-width: 320px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    text-align: center;
  }

  .description {
    margin-bottom: 8px;
    text-align: center;
  }

  .items_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;

    .item {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        background: var(--primary-80);
        width: 60px;
        height: 60px;
      }

      .item_title {
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
}
