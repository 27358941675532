
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 606px;

  @include tablet {
    max-width: 558px;
  }

  @include mobile {
  }

  button {
    width: 221px;
  }

  .title {
    margin-bottom: 8px;
    margin-top: 8px;
  }

  .description {
    margin-bottom: 8px;
  }

  .link {
    margin-bottom: 16px;
  }
}
