
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: left;
  width: 600px;

  @include mobile {
    width: 100%;
  }

  .row {
    display: flex;
    gap: 24px;

    @include mobile {
      flex-direction: column;
    }

    .input {
      min-width: calc(50% - 12px);

      @include mobile {
        min-width: 100%;
      }
    }
  }

  & > button {
    align-self: flex-start;
    display: flex;
    align-items: center;
    gap: 8px;

    p {
      text-decoration: underline;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
  }
}
