
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  max-width: 496px;
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: center;

  @include tablet {
    width: 100%;
    gap: 16px;
  }

  @include mobile {
    gap: 24px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;

    @include tablet {
      flex-direction: column;
    }

    .title {
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }

    .right {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
    }
  }
}
