
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  position: relative;
  width: max-content;

  &.isFull {
    width: 100%;
  }

  svg {
    transition: .3s;
    cursor: pointer;
  }

  .dropdown {
    position: absolute !important;
    z-index: 2;
    margin-top: 8px;
    top: 20px;
    width: 200px;
    border-radius: 4px;
    border: none;
    background: var(--neutral-100);
    display: flex;
    flex-direction: column;
    transition: .3s;
    padding: 0;
    overflow-y: auto !important;
    max-height: 350px;
    height: 0;
    right: 10px;

    &.isOpen {
      height: max-content;
      border: 1px solid var(--neutral-70);
      padding: 8px 0;
    }

    button {
      padding: 12px 16px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: var(--secondary-20);
      transition: .3s;
      text-align: left;
      display: flex;
      align-items: center;
      gap: 8px;

      &:hover {
        background: var(--primary-80);
      }

      &.red {
        color: var(--error-50);
        &:hover {
          background: #FFF0EF;
        }
        svg {
          color: var(--error-50);
        }
      }
    }
  }
}
