
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  width: 100%;
  max-width: 368px;
  height: 235px;
  border-radius: 16px;
  border: 1px solid var(--secondary-80);
  background: var(--primary-100);

  .icon {
    margin-bottom: 32px;
    color: var(--primary-50);
  }

  .title {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin-bottom: 8px;
  }
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .flag {
    margin-bottom: 16px;
  }

  .title {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin-bottom: 4px;
  }

  .description {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    color: var(--neutral-40);
    margin-bottom: 32px;
  }
}