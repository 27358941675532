
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;

  @include tablet {
    gap: 24px;
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .title {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;

      @include tablet {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
      }
    }

    .description {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;

      @include tablet {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @include tablet {
      gap: 20px;
    }

    .row {
      display: flex;
      align-items: center;
      gap: 24px;
      width: 100%;
    }

    .infinite {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .switch {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    .counter {
      max-width: 100px;
    }

    .schedule_title {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      margin-bottom: 8px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-top: 8px;
  }
}