
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    margin-top: 40px;
    width: 100%;

    @include mobile {
      padding: 0 24px;
    }

    .cards_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      width: 100%;

      .card {
        cursor: pointer;
        width: 100%;
        display: flex;
        justify-content: space-between;
        text-align: center;
        max-width: 640px;
        border-radius: 20px;
        border: 1px solid var(--secondary-80);
        background: var(--secondary-100);
        padding: 24px 24px 30px 24px;
        gap: 24px;
        transition: .3s;

        @include tablet {
          flex-direction: column;
          position: relative;
          gap: 20px
        }

        .radio {
          @include tablet {
            position: absolute;
            top: 24px;
            right: 24px;
          }
        }

        &.isActive {
          background: var(--primary-90);
        }

        .icon {
          width: 48px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100px;
          background: var(--primary-70);

          svg {
            color: var(--secondary-30);
          }
        }

        &.disabled {
          cursor: not-allowed;
          border: 1px solid var(--secondary-60);
          background: var(--secondary-90);
          opacity: .5;
        }

        .content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: calc(100% - 128px);

          @include tablet {
            width: 100%;
          }
        }

        .title {
          margin-bottom: 2px;font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
        }

        .description {
          margin-bottom: 16px;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;

          @include mobile {
            margin-bottom: 12px;
          }
        }

        .check_row {
          display: flex;
          align-items: center;
          gap: 8px;
          text-align: left;
          margin-bottom: 8px;

          @include mobile {
            margin-bottom: 6px;
          }

          svg {
            color: var(--primary-30);
          }

          p {
            width: calc(100% - 22px);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;
          }
        }
      }
    }
  }

}
