
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    display: flex;
    flex-direction: column;
    gap: 60px;
    align-items: center;
    width: 100%;

    @media (max-width: 1343px) {
        width: 100%;
        gap: 32px;
    }

    @include tablet {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 40px;
        justify-content: center;
        flex-direction: row;
        align-items: flex-start;
    }

    @media (max-width: 364px) {
        min-width: 0;
    }

    .add_button {
        width: 100%;

        @include tablet {
            width: 320px;
        }

        @include mobile {
            width: 100%;
            max-width: 320px;
        }
    }

    .accounts {
        .title {
            margin-bottom: 16px;
        }
        & > p {
            @media (max-width: 1343px) {
                font-size: 18px;
            }
            @include tablet {
                font-size: 24px;
                line-height: 120%;
            }

            @include mobile {
                font-size: 16px;
            }
        }
    }

    .accounts {
        display: flex;
        flex-direction: column;
        width: 100%;

        @include tablet {
            width: calc(100% - 400px);
            align-self: center;
        }

        @include mobile {
            width: 100%;
        }

        @media (max-width: 380px) {
            width: 100%;
        }
    }

    .accounts_list {
        display: flex;
        flex-direction: column;
        width: 100%;

        @include tablet {
            width: 100%;
            align-self: center;
        }

        @include mobile {
            width: 100%;
        }

        @media (max-width: 380px) {
            width: 100%;
        }
    }

    .account_item {
        padding: 8px 13px;
        border-radius: 8px;
        border: 1px solid var(--secondary-80);
        background: var(--primary-100);
        width: 100%;
        min-width: 282px;
        cursor: pointer;
        transition: .3s;
        margin-bottom: 8px;
        display: flex;
        gap: 12px;

        &:hover {
            background: var(--primary-90);
        }

        .text_wrapper {
            display: flex;
            flex-direction: column;

            .title {
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%;
                margin-bottom: 0;
            }

            .description {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%;
            }
        }
    }

    .add_card {
        display: flex;
        align-items: center;
        gap: 12px;
        width: 100%;
        justify-content: flex-start;
        margin-top: 8px;

        p {
            position: relative;
            text-align: left;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
        }

        & > div {
            width: 40px;
            height: 40px;
            padding: 8px;
            border-radius: 100px;
            background: var(--secondary-30);

            svg {
                color: var(--primary-50);
            }
        }
    }

    .account_skeleton {
        height: 68px;
    }

    .etransfer_skeleton {
        height: 41.8px;
    }
}
