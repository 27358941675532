
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  width: calc(100% - 240px);
  margin: 72px auto;
  padding: 0 14px;

  @include tablet {
    width: 100%;
    margin: 32px 0;
  }

  @include mobile {
    padding: 0 24px;
    gap: 20px
  }

  .date_wrapper {
    display: flex;align-items: center;
    gap: 16px;
  }

  .tabs {
    margin-top: 24px;
  }

  .error {
    font-size: 12px;
    position: absolute;
    right: 16px;
    bottom: 24px;
    max-width: 120px;

    @media (max-width: 1277px) {
      position: static;
      max-width: 100%;
    }
  }

  .points_wrapper_wrapper {
    overflow-x: scroll;
    width: 100%;
  }

  .points_wrapper {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    flex-wrap: wrap;
    margin-top: 40px;

    @include mobile {
      width: 704px;
      flex-wrap: nowrap;
    }

    .point_wrapper {
      display: flex;
      align-items: center;
      border-radius: 8px;
      padding: 24px;
      position: relative;
      width: calc(33% - 16px);
      flex-wrap: wrap;

      @include mobile {
        flex-direction: column;
        width: 224px;
        align-items: center;
        padding: 16px;
        gap: 16px;
      }

      .icon {
        margin-right: 20px;
        width: 50px;
        height: 50px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile {
          margin-right: 0;
          margin-bottom: 16px;
        }
      }
      .button {
        margin-left: 32px;
        font-size: 14px;
        padding: 10px 24px 10px 18px;
        width: auto;
        min-width: auto;
        gap: 8px;
        position: absolute;
        right: 24px;
        bottom: 16px;
        height: auto;
        color: white;
        svg {
          color: white;
        }

        @media (max-width: 1462px) {
          position: static;
          max-width: 100%;
          margin-top: 20px;
          margin-left: 0;
        }
      }
      .text_wrapper {
        display: flex;
        flex-direction: column;
        gap: 6px;
        width: calc(100% - 70px);

        @include mobile {
          text-align: center;
          width: 100%;
        }

        .title {
          display: flex;
          align-items: center;
          gap: 6px;

          @include mobile{
            justify-content: center;
          }
        }
      }

      &.green {
        border: 1px solid var(--secondary-90);
        background: #F8FFFB;

        .icon {
          background: #D1F0E1;
          svg {
            color: var(--success-40);
          }
        }
      }

      &.pending {
        border: 1px solid #FFE9D4;
        background: #FFF8F1;

        .icon {
          background: #FFE9D4;
          svg {
            color: #FF7B00;
          }
        }
      }

      &.total {
        border: 1px solid var(--primary-70);
        background: var(--primary-90);

        .icon {
          background: var(--primary-70);
          svg {
            color: var(--primary-30);
          }
        }
      }
    }
  }

  .table_header {
    display: flex;
    flex-direction: column;
    gap: 28px;
    margin-top: 32px;
    margin-bottom: 24px;

    .filter_row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include mobile {
        flex-direction: column;
        gap: 16px;
      }

      .search {
        max-width: 400px;

        @include mobile {
          max-width: 100%;
        }
      }

      .search_input {
        width: 400px;

        @include mobile {
          width: 100%;
        }
      }

      .range_input{
        width: 300px;

        @include mobile {
          width: 100%;
        }
      }

      .range {
        max-width: 300px;

        @include mobile {
          max-width: 100%;
        }
      }
    }
  }
}