
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 404px;
  width: 100%;

  @include mobile {
    padding: 0 16px;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    margin-bottom: 40px;
    max-height: calc(80vh - 250px);
    overflow-y: auto;
  }

  .header {
    width: 100%;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--secondary-80);
    margin-bottom: 32px;
    text-align: center;
  }

  .attachment_label {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }
}