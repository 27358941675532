
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    position: relative;
    min-height: calc(100vh - 100px);
    width: 100%;

    .planet {
        position: fixed;
        right: 0;
        bottom: 0;
        z-index: -1;
        width: 20%;

        img {
            width: 100%;
        }

        @include tablet {
            width: 285px;
            height: 282px;
        }

        @include mobile {
            display: none;
        }
    }
}
