
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 32px;

    @include tablet {
      margin-bottom: 24px;
    }

    .title {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;

      @include tablet {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
      }
    }

    .description {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;

      @include tablet {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }
  }

  .empty_wrapper {
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    color: var(--neutral-40);
    padding: 16px;

    .empty_icon {
      border-radius: 100px;
      background: var(--neutral-90);
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
    }
  }

  .search_wrapper {
    margin-bottom: 24px;

    @include tablet {
      margin-bottom: 16px;
    }
  }

  .found_contact {
    margin-bottom: 16px;
  }

  .content {
    .add_new {
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      margin-bottom: 16px;
      transition: .3s;

      @include mobile {
        margin-bottom: 8px;
      }

      &:hover {
        background: var(--neutral-90);
      }

      .left {
        display: flex;
        align-items: center;
        gap: 16px;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;

        .avatar {
          width: 40px;
          height: 40px;
          border-radius: 100px;
          background: var(--primary-80);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .skeleton_info {
      height: 70px;
      margin-bottom: 32px;
    }

    .skeleton {
      height: 52px;
      margin-bottom: 24px;
    }

    .input {
      margin-bottom: 24px;
    }

    .info {
      margin-bottom: 32px;
    }

    .results_wrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-bottom: 32px;

      .result {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .result_length {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 160%;
          color: var(--neutral-40);
          width: 100%;
          border-bottom: 1px solid var(--secondary-80);
          padding-bottom: 8px;
        }
      }
    }
  }

  .contact {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    cursor: pointer;
    transition: .3s;
    border-radius: 6px;

    @include mobile {
      padding: 12px;
    }

    .left {
      gap: 16px;
      display: flex;
      align-items: center;

      @include mobile {
        gap: 12px;
      }
    }

    &.isActive {
      background: var(--primary-90);
    }

    &:hover {
      background: var(--neutral-90);
    }

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 100px;
      background: var(--primary-80);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;
    }

    .title {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;

      i {
        color: var(--primary-30);
        font-style: normal;
      }
    }

    .description {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;

      i {
        color: var(--primary-30);
        font-style: normal;
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-top: 8px;
  }
}