
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    display: flex;
    flex-direction: column;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .all {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            color: var(--neutral-40);
        }
    }

    .row_wrapper {
        overflow-x: auto;
        max-width: 1000px;
        width: 100%;

        @media (max-width: 1343px) {
            max-width: 900px;
        }

        @include tablet {
            min-width: 100%;
        }

        .row {
            gap: 16px;
            align-items: center;
            width: max-content;
            display: flex;
            padding-top: 24px;

            @include mobile {
                gap: 16px;
            }

            .new_card {
                border-radius: 10px;
                border: 1px dashed var(--secondary-80);
                background: var(--secondary-90);
                padding: 27px 21px;
                width: 312px;
                height: 196px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: left;
                gap: 12px;

                p {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 130%;
                }

                @include mobile {
                    width: 256px;
                    height: 160px;

                    p {
                        line-height: 1em;
                    }
                }

                & > div {
                    width: 40px;
                    height: 40px;
                    border-radius: 100px;
                    background: var(--secondary-30);
                    align-items: center;
                    display: flex;
                    justify-content: center;

                    svg {
                        color: var(--primary-50);
                    }
                }
            }
        }
    }

    .skeleton {
        width: 311px;
        height: 195px;
    }
}
