
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 48px;

  @include tablet {
    width: 100%;
    flex-direction: row;
    gap: 0;
  }

  .step {
    padding-left: 26px;
    position: relative;

    @include tablet {
      padding-left: 0;
      width: 100%;
    }

    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      color: var(--neutral-40);

      @include tablet {
        text-align: center;
      }
    }

    .point {
      width: 8px;
      height: 8px;
      background: var(--neutral-80);
      position: absolute;
      left: 0;
      top: 8px;
      border-radius: 100%;

      @include tablet {
        bottom: -12px;
        left: calc(50% - 4px);
        top: auto;
      }
    }

    &:before {
      content: "";
      width: 4px;
      height: 72px;
      background: var(--neutral-80);
      position: absolute;
      left: 2px;
      top: 10px;

      @include tablet {
        left: 50%;
        top: calc(100% + 6px);
        width: 100%;
        height: 4px;
      }
    }

    &:last-child {
      &:before {
        display: none;
      }
    }

    &.isDoneStep, &.isCurrentStep {
      p {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%;
        color: var(--secondary-20);
      }

      .point {
        background: var(--primary-40);
      }
    }

    &.isDoneStep {
      &:before {
        background: var(--primary-40);
      }
    }

    &.isPointer {
      cursor: pointer;
    }
  }
}