
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 316px);

  .title {
    margin-top: 32px;
    margin-bottom: 16px;
  }

  .description {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: var(--neutral-40);

    @include mobile {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      max-width: 242px;
    }
  }

  button {
    margin-top: 40px;

    @include mobile {
      margin-top: 32px;
    }
  }
}
