
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .title {
    margin-bottom: 40px;
    text-align: center;
  }

  .description {
    margin-bottom: 48px;
  }

  .form {
    max-width: 440px;
    width: 100%;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  .forgot {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    color: var(--neutral-40);
    text-decoration: none;
  }
}
