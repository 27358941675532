
                    @import "src/styles/_mixins.scss";
                
.root {
    border: none;
    background: none;
    transition: 0.3s;
    padding: 0;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    align-items: center;
    gap: 8px;
    text-align: center;
    justify-content: center;
    width: max-content;
    position: relative;
    border-radius: 12px;

    .spinner_wrapper {
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(61, 61,61,0.5);
        border-radius: 100px;

        svg {
            color: var(--primary-30) !important;
            animation: spin 1s linear infinite;
        }
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    &.full {
        width: 100%;
    }

    &.small {
        padding: 10px 24px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
    }

    &.large {
        padding: 13px 24px;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
    }

    &.medium {
        padding: 10px 24px;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }

    &.normal {
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        padding: 10px 24px;
        min-width: 140px;
    }

    &.icon {
        padding: 8px;
        background: var(--primary-80);
        border: 1px solid var(--primary-80);
        color: var(--secondary-30);
        border-radius: 100px;

        &.large {
            width: 80px;
            height: 80px;
        }

        svg {
            color: var(--secondary-30);
        }

        &:hover {
            border-color: var(--primary-70);
            background: var(--primary-70);
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
        }
    }

    &.icon-dark {
        padding: 8px;
        background: var(--secondary-30);
        border: 1px solid var(--secondary-30);
        border-radius: 100px;
        color: var(--primary-50);

        &.large {
            width: 80px;
            height: 80px;
        }

        svg {
            color: var(--primary-50);
        }

        &:hover {
            border-color: var(--secondary-20);
            background: var(--secondary-20);
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
        }
    }

    &.gold {
        border-radius: 100px;
        background: var(--primary-50);
        color: var(--secondary-20);
        border: 1px solid var(--primary-50);

        svg {
            color: var(--secondary-20);
        }

        &:hover {
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
        }

        &.disabled {
            background: var(--neutral-90);
            border-color: var(--neutral-90);
            color: var(--neutral-60);

            svg {
                color: var(--neutral-60);
            }
        }
    }

    &.secondary {
        background: var(--primary-20);
        color: var(--neutral-30);
        border: 1px solid var(--primary-30);
        border-radius: 100px;

        svg {
            color: var(--neutral-30);
        }

        &:hover {
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
        }

        &.disabled {
            border-color: var(--neutral-70);
            color: var(--neutral-60);

            svg {
                color: var(--neutral-60);
            }
        }
    }

    &.primary {
        background: var(--secondary-20);
        color: var(--primary-50);
        border: 1px solid var(--secondary-20);
        border-radius: 100px;

        .spinner_wrapper {
            background: rgba(0,0,0,0.5);
        }

        p {
            color: var(--primary-50);
        }

        svg {
            color: var(--primary-50);
        }

        &:hover {
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
            border-color: var(--secondary-20);
            background: var(--secondary-20);
        }

        &.disabled {
            background: var(--neutral-70);
            border-color: var(--neutral-70);
            color: var(--secondary-100);

            svg {
                color: var(--secondary-100);
            }
        }
    }

    &.red {
        background: var(--error-50);
        color: white;
        border: 1px solid var(--error-50);
        border-radius: 100px;

        .spinner_wrapper {
            background: rgba(255,255,255,0.5);
        }

        p {
            color: white;
        }

        svg {
            color: white;
        }

        &:hover {
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
            border-color: var(--error-50);
            background: var(--error-50);
        }

        &.disabled {
            background: var(--neutral-70);
            border-color: var(--neutral-70);
            color: var(--secondary-100);

            svg {
                color: var(--secondary-100);
            }
        }
    }

    &.green {
        background: #1DAF68;
        color: white;
        border: 1px solid #1DAF68;
        border-radius: 100px;

        .spinner_wrapper {
            background: rgba(255,255,255,0.5);
        }

        p {
            color: white;
        }

        svg {
            color: white;
        }

        &:hover {
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
            border-color: #1DAF68;
            background: #1DAF68;
        }

        &.disabled {
            background: var(--neutral-70);
            border-color: var(--neutral-70);
            color: var(--secondary-100);

            svg {
                color: var(--secondary-100);
            }
        }
    }

    &.text-red {
        background: none;
        color: var(--error-40);
        border: none;
        border-radius: 100px;
        padding: 0;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;

        .spinner_wrapper {
            background: none;
        }

        p {
            color: var(--error-40);
        }

        svg {
            color: var(--error-40);
        }

        &:hover {
            box-shadow: none;
            border-color: transparent;
            background: none;
        }

        &.disabled {
            background: none;
            border-color: transparent;
            color: var(--secondary-100);

            svg {
                color: var(--secondary-100);
            }
        }
    }

    .notification {
        border-radius: 100px;
        content: "";
        width: 22px;
        height: 22px;
        background: var(--error-50);
        top: -6px;
        right: -6px;
        position: absolute;

        p {
            margin-top: -2px;
            text-align: center;
            color: white;
            width: 100%;
            font-size: 10px;
        }
    }

    &.outlined {
        border: 1px solid var(--primary-30);
        color: var(--primary-30);
        background: var(--neutral-100);
        border-radius: 100px;

        svg {
            color: var(--primary-30);
        }

        &:hover {
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
        }

        &.disabled {
            border-color: var(--neutral-70);
            color: var(--neutral-60);

            svg {
                color: var(--neutral-60);
            }
        }
    }

    &.outlined-dark {
        border: 1px solid var(--secondary-20);
        background: var(--neutral-100);
        color: var(--secondary-20);
        border-radius: 100px;

        svg {
            color: var(--secondary-20);
        }

        &:hover {
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
        }
    }

    &.outlined-red {
        border: 1px solid var(--error-40);
        background: var(--neutral-100);
        color: var(--error-40);
        border-radius: 100px;

        svg {
            color: var(--error-40);
        }

        &:hover {
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
        }
    }

    &:active {
        box-shadow: none !important;
    }

    &.disabled {
        cursor: default;
    }
}
