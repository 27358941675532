
                    @import "src/styles/_mixins.scss";
                
.loader-ring-light {
  width: 240px;
  height: 240px;
  border-radius: 240px;
  box-shadow: 0 6px 0 #F5CC82 inset;
  animation: rotate-360 2s linear infinite;
  margin-bottom: 16px;
}

@keyframes rotate-360 {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}