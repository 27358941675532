
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 32px;

    @include tablet {
      margin-bottom: 24px;
    }

    .title {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;

      @include tablet {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
      }
    }

    .description {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;

      @include tablet {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }
  }

  .email_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .left {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      color: var(--neutral-40);
    }

    .right {
      display: flex;
      align-items: center;
      gap: 16px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    z-index: 1;
    margin-top: 40px;

    .form_row {
      display: flex;
      align-items: flex-start;
      gap: 24px;
      margin-bottom: 32px;

      .checkbox {
        width: 40px;
      }

      .radio {
        align-self: center;
      }
    }

    .send_disclaimer {
      width: calc(100% - 64px);
      span {
        font-weight: 600;
      }
      a {
        color: var(--primary-30);
        font-size: 16px;
        font-style: normal;
        text-decoration: none;
        line-height: 150%;
      }
    }

    .limits {
      display: flex;
      justify-content: space-between;
      width: 100%;
      border-radius: 10px;
      background: var(--primary-90);
      padding: 16px;
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 0;
      border-bottom: 1px solid var(--secondary-80);

      @media (max-width: 850px){
        flex-direction: column;
      }
    }

    .actions {
      display: flex;
      align-items: center;
      gap: 10px;

      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      @include tablet {
        gap: 2px;
      }
    }

    .ddr_info {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.25px;

      svg {
        color: var(--primary-30);
        margin-bottom: -7px;
        margin-right: 4px;
      }

      span {
        font-weight: 600;
      }
    }

    .balance {
      display: flex;
      justify-content: space-between;
      width: 100%;
      border-radius: 4px;
      border: 1px solid var(--primary-40);
      background: var(--primary-90);
      padding: 16px;
      font-weight: 600;
    }

    .fees_container {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      overflow: hidden;
      transition: .3s;
      border-radius: 8px;
      padding: 0 16px;
      background: var(--secondary-100);

      &.isOpen {
        padding: 16px !important;
        border: 1px solid var(--secondary-80);
      }

      .fees_wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .row {
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            font-size: 14px;
            font-weight: 400;
            line-height: 150%;
          }
        }
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-top: 40px;

    @include tablet {
      margin-top: 32px;
    }
  }
}