
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  margin: 72px auto;
  padding: 0 14px;
  gap: 24px;

  @include tablet {
    width: 100%;
    margin: 32px 0;
  }

  @include mobile {
    padding: 0 24px;
    gap: 20px
  }

  .head {
    button {
      font-size: 24px;
      font-weight: 700;
      line-height: 120%;

      @include mobile {
        font-size: 16px;
      }
    }
  }

  .content_wrapper {
    display: flex;
    flex-direction: column;
    padding: 24px;
    border: 1px solid var(--secondary-80);
    border-radius: 8px;
    width: 100%;
    gap: 32px;

    .content {
      display: flex;
      flex-wrap: wrap;
      column-gap: 16px;
      row-gap: 48px;

      @include tablet {
        column-gap: 8px;
        row-gap: 24px;
      }

      @include mobile {
        gap: 12px
      }

      .item {
        width: calc(25% - 12px);
        display: flex;
        flex-direction: column;
        gap: 8px;

        .block_requester {
          display: flex;
          align-items: center;
          gap: 4px;
          color: var(--primary-10);

          svg {
            color: var(--primary-10);
          }
        }

        @include tablet {
          width: calc(50% - 8px);
        }

        @include mobile {
          width: 100%;
        }
      }
    }

    .button {
      align-self: center;
    }
  }

  .skeleton {
    height: 304px;
  }
  .actions {
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: center;
  }
}
