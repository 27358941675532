
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  position: relative;
  width: max-content;

  &.isFull {
    width: 100%;
  }

  svg {
    transition: .3s;
  }

  & .isOpen {
    svg {
      transform: rotate(180deg);
    }
  }

  .dropdown {
    position: absolute !important;
    z-index: 2;
    margin-top: 8px;
    top: 80px;
    width: 100%;
    border-radius: 4px;
    border: none;
    background: var(--neutral-100);
    display: flex;
    flex-direction: column;
    padding: 0;
    transition: .3s;
    overflow-y: auto !important;
    max-height: 200px;
    height: 0;

    &.isOpen {
      height: max-content;
      border: 1px solid var(--neutral-70);
      padding: 4px 0;
    }

    button {
      padding: 8px 12px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: var(--secondary-20);
      transition: .3s;
      text-align: left;

      &:hover {
        background: var(--primary-80);
      }
    }
  }
}
