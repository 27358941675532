
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  width: 416px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mobile {
    width: 100%;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 100%;
    margin: 16px 0 32px;
    padding-top: 16px;
    border-top: 1px solid var(--secondary-80);

    @include tablet {
      margin-bottom: 24px;
    }

    @include mobile {
      width: 100%;
      gap: 8px;
    }

    .row {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}
