
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  width: 416px;
  display: flex;
  flex-direction: column;

  @media (max-width: 514px) {
    width: 100%;
  }

  & > p {
    text-align: center;
  }

  .content {
    padding: 16px 0 32px;
    margin-top: 16px;
    border-top: 1px solid var(--secondary-80);
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    .row {
      width: 100%;

      span {
        font-weight: 500;
      }
    }
  }
}
