
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 23px;
    background-size: cover;
    background-repeat: no-repeat;
    width: 320px;
    height: 210px;
    border-radius: 15px;
    cursor: pointer;
    transition: .3s;
    transform-origin: left bottom;
    position: relative;

    &:hover {
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
        transform: scale(1.03);
    }

    .status {
        position: absolute;
        top: 16px;
        left: 16px;
        padding: 5px 16px;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
    }

    &:active {
        box-shadow: none;
    }

    .amount {
        color: var(--neutral-100);
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        position: absolute;
        left: 24px;
        top: 83px;
    }

    .content_wrapper {
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 0 38px 38px 38px;

        &.isMini {
            padding: 0 16px 24px 24px;
        }

        .row {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .badge {
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            color: white;
            padding: 5px 12px;
            border-radius: 10px;
            background: rgba(255, 255, 255, 0.20);
            width: max-content;
            position: absolute;
            top: 16px;
            left: 16px;
        }
    }

    .number {
        color: var(--neutral-100);
        font-size: 12.261px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 12.261px */
        letter-spacing: 0.723px;
    }

    .card_holder {
        font-size: 9.805px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        color: var(--primary-50);
    }

    .expiry {
        margin-top: 9.45px;
        color: #FFF;
        font-size: 7.356px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        text-transform: uppercase;

        span {
            color: var(--primary-50);
            font-size: 7.356px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            text-transform: uppercase;
            margin-left: 5px;
        }
    }
}
