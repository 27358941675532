
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  height: 100vh;
  position: relative;
  display: flex;
  width: 100%;

  & > div {
    max-width: 50%;
    width: 100%;

    @include tablet {
      max-width: 100%;
    }
  }

  .language {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: var(--neutral-40);

    p {
      transition: .3s;
      color: var(--neutral-40);

      &.isActive {
        font-weight: 700;
        color: var(--primary-30);
      }
    }
  }

  .links_wrapper {
    display: flex;
    align-items: center;
    gap: 32px;

    @include mobile {
      flex-direction: column;
      gap: 12px;
      align-items: flex-start;
    }

    a {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      color: var(--neutral-40);
      text-decoration: none;
    }
  }

  .content_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @include tablet {
      height: 100vh;
      justify-content: space-between;
    }

    .content {
      width: 100%;
    }

    .body {
      width: 100%;

      @include mobile {
        padding: 0 16px;
      }
    }

    .content_header {
      width: 100%;
      padding: 48px 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 64px;

      @include mobile {
        padding: 26px 16px;
      }

      .content_actions {
        display: flex;
        align-items: center;
        gap: 40px;

        .language {
          display: none;

          @include tablet {
            display: flex;
          }
        }
      }

      a {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        color: var(--primary-30);
        text-decoration: none;
      }
    }

    .content_footer {
      width: 100%;
      padding: 48px 60px;
      align-items: center;
      justify-content: space-between;
      display: none;

      @include mobile {
        padding: 24px 16px;
      }

      @include tablet {
        display: flex;
      }

      .illustration_footer {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        color: var(--neutral-40);
      }
    }
  }

  .illustration_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    background: var(--primary-90);

    @include tablet {
      display: none;
    }

    .illustration {
      max-width: 732px;
      width: 100%;
      padding: 0 116px;
    }

    .illustration_header, .illustration_footer {
      width: 100%;
      padding: 48px 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .illustration_footer {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      color: var(--neutral-40);
    }
  }
}
