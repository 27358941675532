
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  max-width: 500px;
  gap: 16px;
  margin-bottom: 40px;

  img {
    max-width: 200px;
    margin-bottom: 40px;
  }

  .title {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    margin-bottom: 8px;
  }

  .description {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: var(--neutral-40);
    margin-bottom: 24px;
    max-width: 372px;
  }

  .info_title {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    margin-bottom: 3px;
    text-align: left;
  }

  .info_description {
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
  }
}
