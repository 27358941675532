
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .sent_illustration {
    margin-bottom: 32px;
  }

  .title {
    margin-bottom: 16px;
    text-align: center;
  }

  .description {
    margin-bottom: 40px;
    max-width: 392px;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: var(--neutral-40);

    &.isLarge {
      max-width: 500px;
    }
  }

  .form {
    max-width: 440px;
    width: 100%;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 28px;
  }

  .back {
    display: flex;
    align-items: center;
    color: var(--neutral-40);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    margin-top: 24px;

    svg {
      color: var(--neutral-40);
    }
  }
}
