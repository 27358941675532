
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;

  @include tablet {
    gap: 24px;
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .title {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;

      @include tablet {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
      }
    }

    .description {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;

      @include tablet {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }
  }

  .limits_wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-radius: 6px;
    background: var(--neutral-90);
    padding: 16px;

    .title {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 160%;
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .right {
      display: flex;
      flex-direction: column;
      gap: 2px;

      .active_limit {
        display: flex;
        align-items: center;
        gap: 32px;

        .active_title {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 160%;
        }

        .active_amount {
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 160%;
        }
      }

      .daily_limit {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .daily_title {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
        }

        .daily_amount {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
        }
      }
    }
  }

  .balance {
    display: flex;
    gap: 8px;
    align-content: center;
    width: 100%;
    border-radius: 4px;
    border: 1px solid var(--primary-40);
    background: var(--primary-90);
    padding: 16px;

    @include tablet {
      margin-bottom: 4px;
    }

    span {
      font-weight: 600;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @include tablet {
      gap: 20px;
    }

    .row {
      display: flex;
      align-items: center;
      gap: 24px;
      width: 100%;
    }

    .schedule_switch {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-top: -24px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-top: 8px;
  }
}