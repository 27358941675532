
                    @import "src/styles/_mixins.scss";
                
.skeleton {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 10px;
  background-color: var(--neutral-80);
  border-radius: 10px;
  width: 100%;
}

.skeleton div {
  animation: shimmer 3s infinite;
  border-radius: 4px;
  position: absolute;
  width: 50px;
  height: 100%;
}

@keyframes shimmer {
  0% {
    left: 0;
  }
  50% {
    left: calc(100% - 50px);
  }
  100% {
    left: 0;
  }
}

.skeleton div {
  background: linear-gradient(
                  to right,
                  var(--neutral-80) 0%,
                  var(--neutral-90) 40%,
                  var(--neutral-90) 60%,
                  var(--neutral-80) 100%
  ) no-repeat;
}