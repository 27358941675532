
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  margin-top: -128px;
  gap: 105px;

  @include tablet {
    gap: 0;
    margin-top: -64px;
  }

  @include mobile {
    flex-direction: column-reverse;
    margin-top: -38px;
  }

  .link {
    color: var(--primary-30);
    text-decoration: none;
  }

  .gold_button {
    background: var(--primary-50);
    padding: 10px 24px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 100px;
  }

  .dark_button {
    background: var(--secondary-20);
    color: var(--primary-50);
    padding: 10px 24px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 100px;
  }

  .left {
    padding-top: 72px;
    display: flex;
    flex-direction: column;
    gap: 48px;
    width: calc(100% - 495px);
    padding-left: calc((100% - 1380px) / 2);

    @media (max-width: 1460px) {
      padding-left: 40px;
    }

    @include tablet {
      width: calc(100% - 344px);
      padding: 40px;
      margin-left: 0;
    }

    @include mobile {
      padding: 48px 20px;
      width: 100%;
    }

    .header_wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
      max-width: 465px;

      .description {
        font-size: 16px;
        color: var(--neutral-40);
      }

      @include tablet {
        max-width: 438px;
        justify-content: center;
        margin: 0 auto;

        .title {
          font-size: 24px;
        }
      }

      @include mobile {
        justify-content: center;
        text-align: center;
      }
    }

    .content_wrapper {
      display: flex;
      justify-content: space-between;

      @media (max-width: 1600px) {
        align-items: center;
      }

      @media (max-width: 1400px) {
        flex-direction: column;
        align-items: flex-start;
      }

      @include mobile {
        gap: 30px;
      }

      .image_wrapper {
        width: calc(100% - 390px);
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        min-height: 250px;
        gap: 40px;

        @media (max-width: 1400px) {
         min-height: auto;
          width: 100%;
        }

        @include mobile {
          gap: 24px;
          margin-right: -40px;
        }

        img {
          width: 100%;
        }

        .dots_wrapper {
          display: flex;
          align-items: center;
          gap: 8px;
          z-index: 10;

          .dot {
            cursor: pointer;
            width: 10px;
            height: 10px;
            border-radius: 100%;
            background: var(--secondary-80);
            transition: .3s;

            &.isActive {
              width: 16px;
              height: 16px;
              background: #000;
            }
          }
        }
      }
    }

    .slide {
      @include mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .icon {
        width: 72px;
        height: 72px;
        border-radius: 100%;
        background: var(--primary-90);
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;

        @include tablet {
          width: 64px;
          height: 64px;
        }

        img {
          width: 40px;
          height: 40px;
          color: var(--primary-40);

          @include tablet {
            width: 32px;
            height: 32px;
          }
        }
      }

      .content {
        width: 100%;
        max-width: 390px;

        @include mobile {
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0 14px;
        }

        .title {
          margin-bottom: 16px;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 130%;

          @include tablet {
            font-size: 20px;
            line-height: 140%;
            margin-bottom: 12px;
          }

          span {
            color: var(--primary-30);
          }
        }

        .description {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 160%;
          color: var(--neutral-40);
          margin-bottom: 40px;

          @include tablet {
            font-size: 14px;
            margin-bottom: 32px;
          }
        }
      }
    }
  }

  .right {
    width: 495px;
    height: 100vh;
    background: var(--primary-90);
    padding: 160px 40px 40px 40px;
    display: flex;
    position: sticky;
    right: 0;
    top: 0;
    justify-content: space-between;
    flex-direction: column;
    gap: 24px;

    @include tablet {
      width: 344px;
    }

    @include mobile {
      padding: 32px 20px;
      width: 100%;
      height: auto;
      position: relative;
    }

    .arrow {
      top: 0;
      left: 0;
      position: absolute;
      max-width: 100%;

      @include mobile {
        top: 40px;
      }
    }

    .rocket {
      @media (max-width: 600px) {
        display: none;
      }
    }

    .content {
      z-index: 10;
      flex-direction: column;
      align-items: center;
      text-align: center;
      display: flex;
      gap: 40px;

      @include mobile {
        gap: 32px;
      }

      .sign_wrapper {
        display: flex;
        align-items: center;
        gap: 8px;

        p, a {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 160%;
        }
      }

      .text_wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-width: 311px;
        width: 100%;

        .title {
          font-size: 32px;

          @include mobile {
            font-size: 24px;
          }
        }

        .description {

          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 160%;
        }
      }

      .actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;

        .links {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          color: var(--neutral-40);
          max-width: 280px;
          width: 100%;

          a {
            margin-left: 8px;
            margin-right: 8px;
            text-decoration: none;
          }
        }
      }
    }

    .illustration {
      align-self: flex-end;

      @include mobile {
        position: absolute;
        bottom: 24px;
        right: 24px;
        width: 178px;
      }

      @media (max-width: 600px) {
        display: none;
      }
    }
  }
}