
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;

  @include tablet {
    gap: 24px;
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .title {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;

      @include tablet {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
      }
    }

    .description {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;

      @include tablet {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }
  }

  .summary {
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-radius: 16px;
    border: 1px solid var(--secondary-80);
    background: var(--secondary-100);
    padding: 24px;

    .block {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .content {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 160%;
          color: var(--neutral-40);

          span {
            font-style: normal;

            &.bold {
              font-size: 16px;
              font-weight: 600;
              line-height: 150%;
            }
          }
        }
      }
    }

    .divider {
      width: 100%;
      height: 1px;
      background: #E1DCD0;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-top: 8px;
  }
}