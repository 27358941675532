
                    @import "src/styles/_mixins.scss";
                
.root {
  max-width: 210px;
  transition: .3s;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  @include mobile {
    max-width: 100%;
  }

  .left_icon {
    position: absolute;
    padding: 12px;
    z-index: 1;
    top: 3px;
    left: 4px;
    font-size: 18px;
    gap: 4px;
    color: #000;
  }

  .right_icon {
    position: absolute;
    padding: 12px;
    display: flex;
    align-items: center;
    gap: 4px;
    color: #000;
    font-size: 18px;
    z-index: 1;
    top: 4px;
    right: 4px;
  }

  &.full {
    max-width: 100%;
  }

  .label {
    transition: .3s;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: var(--secondary-20);
    z-index: 1;
    cursor: text;
    margin-bottom: 8px;
  }

  .helper_wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    gap: 8px;
  }

  .helper_text {
    transition: .3s;
    color: var(--neutral-50);
    margin-left: 16px;
    display: block;
    margin-top: 4px;
  }

  .counter {
    color: var(--neutral-50);
    font-size: 12px;
    margin-top: 4px;
  }

  .error_text {
    transition: .3s;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: var(--error-40);
    margin-left: 16px;
    display: block;
  }

  .input_wrapper {
    width: 100%;
    position: relative;
    transition: .3s;
    display: block;

    textarea {
      outline: none;
      width: 100%;
      padding: 18px 16px;
      border-radius: 4px;
      border: 1px solid var(--neutral-40);
      height: 128px;
      font-size: 16px;
      font-weight: 400;
      line-height: 100%;
      color: var(--secondary-20);
      resize: none;

      &::placeholder {
        color: var(--neutral-50);
        font-size: 16px;
        font-weight: 400;
        line-height: 100%;
      }
    }

    button {
      cursor: default;
    }

    svg {
      color: var(--secondary-20);
    }
  }

  &.isOnclickIcon {
    .input_wrapper {
      button {
        cursor: pointer;
      }
    }
  }

  &.isLeftIcon {
    .label {
      left: 52px;

      &.isActive {
        left: 16px;
      }
    }

    .input_wrapper {

      textarea {
        padding-left: 52px;
      }
    }
  }

  &.isRightIcon {
    .input_wrapper {
      textarea {
        padding-right: 52px;
      }
    }
  }

  &:hover {
    .helper_text {
      color: var(--neutral-30)
    }

    .input_wrapper {

      textarea {
        transition: none;
        border-color: var(--secondary-20);
      }
    }
  }

  &:focus-within {
    .label {
      color: var(--primary-30);
    }

    &.isLeftIcon {
      .label {
        left: 16px;
      }
    }

    .input_wrapper {

      textarea {
        border-color: var(--primary-30);
        border-width: 2px;

        &::placeholder {
          width: max-content;
        }
      }
    }
  }

  &.error {
    .label, .helper_text {
      color: var(--error-40);
    }
    .input_wrapper {
      textarea {
        border-color: var(--error-40);
        color: var(--error-40);
      }
      svg {
        color: var(--error-40);
      }
    }

    &:focus-within {
      .input_wrapper {
        textarea {
          &::placeholder {
            color: var(--error-40);
          }
        }
      }
    }
  }

  &.disabled {
    .label, .helper_text {
      color: var(--neutral-70) !important;
    }
    .input_wrapper {
      textarea {
        border-color: var(--neutral-70);
        color: var(--neutral-70);
        background: var(--neutral-100);
      }
      svg {
        color: var(--neutral-70);
      }
    }
  }
}
