
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  text-align: center;

  img {
    width: 280px;
  }

  .link {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    color: var(--primary-30);
  }

  .text_wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .title {
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
    }

    .description {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      color: var(--color-neutral-40);
      max-width: 414px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-top: 8px;
  }
}