
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  min-height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;

  @include tablet {
    flex-direction: column;
    background: var(--primary-100);
  }

  .left {
    display: flex;
    flex-direction: column;
    gap: 64px;
    z-index: 3;
    width: 100vw;
    padding: 72px calc(50% - 1425px / 2) 32px calc(50% - 1425px / 2);
    background: var(--primary-100);
    transition: .3s;

    &.isOpen {
      width: calc(897px + (100% - 1425px) / 2);
      padding: 72px 24px 32px calc((100% - 1425px) / 2);
    }

    @media (max-width: 1457px) {
      padding: 72px 24px 32px;

      &.isOpen {
        width: calc(100% - 456px);
        padding: 72px 24px 32px;
      }
    }

    @include tablet {
      width: 100% !important;
      padding: 32px 16px !important;
    }

    @include mobile {
      gap: 32px;
    }
  }

  .right {
    width: 0;
    padding: 72px 0 32px;
    transition: .3s;

    &.isOpen {
      padding: 72px 0 32px 36px;
      width: max-content;
      margin-right: calc((100% - 1425px) / 2);
    }

    @media (max-width: 1457px) {
      margin-right: 0;

      &.isOpen {
        width: 398px;
        padding: 72px 16px 36px;
        margin-right: calc((100% - 1425px) / 2);
      }
    }

    @include tablet {
      position: absolute;
      transform: translateX(100vw);
      width: 398px;
      height: 100%;
      background: var(--neutral-100);
      padding: 36px 16px;
      z-index: 5;

      &.isOpen {
        transform: translateX(calc(100vw - 398px));
        padding: 36px 16px;
      }
    }

    @media (max-width: 430px) {
      &.isOpen {
        width: 100%;
        margin-right: 0;
        transform: translateX(0);
      }
    }
  }
}
