
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    background: var(--secondary-20);
    padding: 32px 0;

    @media (max-width: 1449px) {
        padding: 44px 36px 44px 37px;
    }

    @include tablet {
        padding: 31px 36px 30px 37px;
    }

    @include mobile {
        padding: 19px 29px 18px;
    }

    .container {
        width: 1378px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 1449px) {
            width: 100%;
        }

        @include tablet {
            width: 100%;
        }

        .mobile_justify {
            display: none;

            @include mobile {
                display: block;
            }
        }

        & > img {
            width: 140px;

            @include tablet {
                width: 100px;
            }
        }

        .progress_bar {
            @include mobile {
                display: none;
            }
        }

        .right {
            width: 140px;

            @include tablet {
                width: 100px;
            }

            @include mobile {
                width: 80px;
                margin-left: -80px;
            }



            .language {
                text-transform: uppercase;
                color: var(--primary-100);

                p {
                    color: var(--primary-100);

                    &.isActive {
                        color: var(--primary-50);
                    }
                }
            }
        }
    }
}
