
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;

    .content {
        display: flex;
        flex-direction: column;
        gap: 21px;

        .table_header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .left {
                display: flex;
                align-items: center;
                gap: 16px;
            }
        }
    }

    .all {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        color: var(--neutral-40);
    }
}
