
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 500px;

  @include tablet {
    width: 100%;
    max-width: 100%;
  }

  @include mobile {
    width: 100%;
    max-width: 100%;
  }

  .title {
    margin-top: 32px;
    margin-bottom: 12px;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    text-align: center;

    @include tablet {
      margin-top: 16px;
    }
  }

  .description, .description_pending {
    max-width: 322px;
    text-align: center;
    width: 100%;
    margin-bottom: 40px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  .description_pending {
    max-width: 406px;
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}
