
                    @import "src/styles/_mixins.scss";
                
.main_wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: 1300px) {
    margin-left: 16px;
  }

  .table_actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 8px;

    .delete {
      &:hover {
        svg {
          color: var(--error-40)
        }
      }
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: 100%;

    & > .actions {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 100%;

      .send_disclaimer {
        a {
          color: var(--secondary-20);
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
        }
      }

      .limits {
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-radius: 10px;
        background: var(--primary-90);
        padding: 16px;
      }

      .header {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding-bottom: 24px;
        border-bottom: 1px solid var(--secondary-80);
        width: 100%;

        .back {
          position: absolute;
          left: 0;
        }

        & > p {
          @include mobile {
            max-width: 212px;
            text-align: center;
          }
        }
      }

      .actions {
        display: flex;
        align-items: center;
        gap: 10px;

        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        @include tablet {
          gap: 2px;
        }
      }

      .ddr_info {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.25px;

        svg {
          color: var(--primary-30);
          margin-bottom: -7px;
          margin-right: 4px;
        }

        span {
          font-weight: 600;
        }
      }

      .balance {
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-radius: 4px;
        border: 1px solid var(--primary-40);
        background: var(--primary-90);
        padding: 16px;
        font-weight: 600;
      }

      .fees_container {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        overflow: hidden;
        transition: .3s;
        border-radius: 8px;
        padding: 0 16px;
        background: var(--secondary-100);

        &.isOpen {
          padding: 16px !important;
          border: 1px solid var(--secondary-80);
        }

        .fees_wrapper {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .row {
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
              font-size: 14px;
              font-weight: 400;
              line-height: 150%;
            }
          }
        }
      }
    }

    .form_row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
      margin-bottom: 8px;
    }

    .empty {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      padding-top: 32px;

      img {
        width: 200px;
        margin-bottom: 32px;
      }

      .title {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        margin-bottom: 12px;
      }

      .description {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        color: var(--neutral-40);
        margin-bottom: 40px;
      }
    }

    .search_row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 24px;

      @include mobile {
        flex-direction: column;
        gap: 16px;
      }
    }

    .search {
      max-width: 480px;

      @include mobile {
        max-width: 100%;
      }
    }

    .new_contact {
      align-self: flex-end;
    }

  }

  .add_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 664px;

    .search_wrapper {
      width: 100%;
      margin-bottom: 32px;

      .description {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        margin-bottom: 32px;
      }
    }

    .data_input {
      width: 100%;
      margin-bottom: 24px;
    }

    .submit {
      margin-top: 16px;
    }

    .results_wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 32px;

      .result {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 24px;

        .result_length {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 160%;
          color: var(--neutral-40);
          width: 100%;
          border-bottom: 1px solid var(--secondary-80);
          padding-bottom: 8px;
        }

        .title {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          padding: 16px;
          cursor: pointer;
          transition: .3s;

          &:hover {
            background: var(--neutral-90);
          }

          i {
            color: var(--primary-30);
            font-style: normal;
          }
        }
      }
    }
  }

  .chosen_payee {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    width: 100%;
    border-radius: 6px;
    background: var(--primary-90);
  }
}