
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  padding: 48px;
  width: 500px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 74vh;
  gap: 40px;
  //height: (100vh - 340px);

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;

    .icon {
      height: 40px;
      width: 40px;
      border-radius: 100px;
      background: var(--primary-80);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .title {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    width: 100%;

    .user {
      border-radius: 16px;
      border: 1px solid var(--secondary-80);
      background: var(--primary-100);
      padding: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .user_avatar {
        width: 56px;
        height: 56px;
        border-radius: 100px;
        background: var(--primary-80);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        margin-bottom: 12px;
      }

      .user_name {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        margin-bottom: 2px;
      }

      .user_email {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        margin-bottom: 2px;
      }

      .block_user {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        margin-top: 16px;
      }

      .divider {
        width: 100%;
        height: 1px;
        background: var(--secondary-80);
        margin: 16px 0;
        max-width: 300px;
      }

      .payment_id {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 2px;
        width: 100%;
        margin-bottom: 9px;

        .id {
          display: flex;
          align-items: center;
          gap: 6px;
        }

        p {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
        }

        button {
          height: 18px;
        }
      }
    }

    .details {
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: 100%;

      .row {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
  }
}