
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  width: 418px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include tablet {
    width: 418px;
  }

  @include mobile {
    width: 100%;
  }

  .content {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    padding: 16px 0 32px;
    border-top: 1px solid var(--secondary-80);
    width: 100%;

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 16px 0;
    }
  }
}
