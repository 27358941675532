
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  margin-top: -128px;
  gap: 105px;

  @include tablet {
    gap: 0;
    margin-top: -64px;
  }

  @include mobile {
    flex-direction: column-reverse;
    margin-top: -38px;
  }

  .link {
    color: var(--primary-30);
    text-decoration: none;
  }

  .dark_button {
    background: var(--secondary-20);
    color: var(--primary-50);
    padding: 10px 24px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 100px;
  }

  .left {
    padding-top: 72px;
    display: flex;
    flex-direction: column;
    gap: 48px;
    width: calc(100% - 700px);
    padding-left: calc((100% - 1380px) / 2);
    margin-bottom: 70px;

    @media (max-width: 1460px) {
      padding-left: 40px;
      width: calc(100% - 394px);
    }

    @include tablet {
      padding: 40px;
      margin-left: 0;
    }

    @include mobile {
      padding: 24px 20px;
      width: 100%;
      gap: 0;
    }

    .header_wrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
      max-width: 561px;

      .description {
        font-size: 16px;
        color: var(--neutral-40);
      }

      @include tablet {
        max-width: 438px;
        justify-content: center;
        margin: 0 auto;

        .title {
          font-size: 24px;
        }
      }

      @include mobile {
        justify-content: center;
        text-align: center;
      }
    }

    .content_wrapper {
      .description {
        margin-bottom: 24px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }

      .checkboxes {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-bottom: 16px;

        .checkbox_wrapper {
          display: flex;
          align-items: center;
          gap: 8px;

          p {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
          }
        }
      }

      .textarea {
        margin-bottom: 32px;
        max-width: 600px;
      }
    }

    .sign_wrapper {
      display: none;
      align-items: center;
      align-self: center;
      flex-wrap: wrap;
      gap: 8px;

      @include mobile {
        display: flex;
        margin-bottom: 40px;
        margin-top: 24px;
      }

      p, a {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
      }
    }
  }

  .right {
    width: 700px;
    height: 100vh;
    background: var(--primary-90);
    padding: 220px 40px 40px 40px;
    display: flex;
    position: sticky;
    right: 0;
    top: 0;
    flex-direction: column;
    align-items: center;
    gap: 105px;

    @media (max-width: 1400px) {
      width: 394px;
    }

    @include mobile {
      padding: 20px 20px 0 20px;
      width: 100%;
      height: auto;
      position: relative;
      background: none;
      gap: 0;
    }

    .content {
      z-index: 10;
      flex-direction: column;
      align-items: center;
      text-align: center;
      display: flex;
      gap: 40px;

      @include mobile {
        gap: 32px;
      }

      .sign_wrapper {
        display: flex;
        align-items: center;
        gap: 8px;

        @include tablet {
          flex-direction: column;
        }

        @include mobile {
          display: none;
        }

        p, a {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 160%;
        }
      }
    }

    .illustration {
      width: 400px;

      @include mobile {
        width: 200px;
      }
    }
  }
}