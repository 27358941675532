
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  @include tablet {
    gap: 24px;
  }

  @include mobile {
    gap: 16px;
    align-items: flex-start;
  }

  .same {
    display: flex;
    align-items: center;

    @include mobile {
      margin-bottom: 16px;
    }
  }

  .description {
    width: 100%;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    max-width: 500px;
    text-align: center;

    &.isPhysical {
      max-width: 388px;
    }
  }

  .column {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .row {
    width: 100%;
    display: flex;
    gap: 24px;
  }
}
