
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  width: 320px;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  @media (max-width: 420px) {
    width: 100%;
  }

  .title {
    margin-bottom: 17px;
    text-align: center;

    @include mobile {
      margin-bottom: 24px;
    }
  }

  .inputs_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .balance_wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .balance {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px 11px 16px;
    border-radius: 4px;
    border: 1px solid var(--primary-50);
    background: var(--primary-90);


  }

  .input_wrapper {
    display: flex;
    flex-direction: column;
    gap: 18px;
    width: 100%;

    @include mobile {
      gap: 0;
    }
  }
}
