
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    border: 1px solid var(--primary-50);
    background: var(--primary-90);
    padding: 16px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 200px;

    img {
        border-radius: 100px;
        width: 40px;
        height: 40px;
    }

    .currency_wrapper {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        gap: 12px;
    }

    .amount {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        text-align: left;
    }

    &:hover {
        background: var(--primary-80);
    }
}
