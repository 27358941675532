
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  max-width: 900px;
  width: 100%;
  padding: 48px;
  max-height: 80vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;

  .title {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--secondary-80);
    width: 100%;
    text-align: center;
    margin-bottom: 32px;

    @include mobile {
      margin-bottom: 16px;
    }
  }

  .content_wrapper {
    overflow-y: auto;
    height: calc(80vh - 152px);
    border-radius: 16px;
    border: 1px solid var(--secondary-80);
    background: var(--secondary-100);
    width: 100%;

    .content {
      width: 100%;
      padding: 40px;
    }

    .footer {
      display: flex;
      align-items: center;
      gap: 12px;
      width: 100%;
      padding: 40px;
      border-top: 1px solid var(--secondary-80);
    }

    .caption {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }

    .center {
      text-align: center;
    }

    table {
      border: none;
      border-collapse: collapse;
      width: 100%;

      thead {
        tr {
          td {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            padding: 21px 12px;
            text-align: left;

            &:first-child {
              width: 220px;
            }

            &:nth-child(2) {
              width: 120px;
            }
          }
        }
      }

      tbody {
        tr {
          &:nth-child(2n + 1) {
            background: var(--neutral-90);
          }
          td {
            text-align: left;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 160%;
            padding: 19px 12px;

            &:nth-child(2) {
              text-align: center;
            }

            &:last-child {
              font-weight: 400;
            }
          }
        }
      }

      &.two_column {
        tbody {
          tr {
            th {
              &:nth-child(2) {
                text-align: left !important;
                font-weight: 400;
              }
            }
          }
        }
      }

      &.user {
        tbody {
          tr {
            td {
              font-weight: 400;
              width: auto !important;
              text-align: left;
            }
          }
        }
      }
    }

    h1 {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      text-align: center;
      margin-top: 32px;
      margin-bottom: 40px;

      @include mobile {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        margin-bottom: 24px;
      }
    }

    h2 {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      text-align: center;
      margin-top: 40px;
      margin-bottom: 32px;

      @include mobile {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%;
        margin-bottom: 16px;
      }
    }

    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;

      &.point {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%;
        padding-left: 8px;
      }
    }

    .nested_list {
      padding-left: 16px;
      span {
        margin-right: 12px;
      }
    }

    .info {
      padding: 16px;
      border-radius: 6px;
      background: #F3F3F3;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;

      .title {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%;
        text-decoration: underline;
      }
    }

    b {
      font-weight: 600;
    }

    ul {
      padding-left: 34px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      li {
        position: relative;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;

        &:before {
          position: absolute;
          width: 6px;
          height: 6px;
          border-radius: 100px;
          background: var(--neutral-60);
          content: '';
          left: -12px;
          top: 10px;
        }
      }
    }
  }
}