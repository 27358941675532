
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    width: 100%;
    overflow-x: auto;

    .table {
        min-width: 100%;
        width: max-content;
        border-collapse: collapse;

        .header {
            th {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 130%;
                width: max-content;
                text-align: left;
                position: relative;

                @include mobile {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 120%;
                }

                &.isSort {
                    cursor: pointer;
                }

                &.isActions {
                    & > div {
                        & > p {
                            width: 100%;
                        }
                    }
                }

                & > div {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    position: relative;
                    min-width: 121px;
                    width: 100%;
                    padding: 21px 16px;

                    & > button {
                        width: 24px;
                        height: 24px;
                    }

                    svg {
                        transition: 0.3s;

                        &.isDesc {
                            transform: rotate(180deg);
                        }
                    }

                    .dropdown {
                        position: absolute;
                        top: 44px;
                        left: 0;
                        width: max-content;
                        border: 1px solid var(--neutral-70);
                        background: var(--Neutral-N-90, #f8f8f8);
                        z-index: 2;
                        height: max-content;

                        .item {
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 150%;
                            color: #000;
                            padding: 2px 16px;

                            &:hover {
                                background: var(--primary-90);
                            }

                            &.isActive {
                                color: var(--primary-20);
                                background: var(--primary-90);
                            }
                        }
                    }
                }

                &:not(:first-child) {
                    & > div {
                        padding: 0 8px;
                    }
                }

                &:first-child {
                    padding-left: 10px;
                }

                &:last-child {
                    padding-right: 10px;
                }
            }

            &.isSelect {
                th:first-child {
                    & > div {
                        width: 70px;
                        min-width: 70px;
                    }
                }
            }

            &.isActions {
                th:last-child {
                    width: max-content;

                    & > div > p {
                        text-align: right;
                    }
                }
            }
        }

        .body {
            tr {
                transition: 0.3s;

                &.isOnclick {
                    cursor: pointer;
                }

                &:nth-child(2n + 1) {
                    background: var(--neutral-90);
                }

                &:hover {
                    background: var(--neutral-90);
                }
            }

            td {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%;
                width: max-content;
                text-align: left;
                position: relative;

                @include mobile {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                }

                &:not(:last-child) {
                    & > div {
                        margin: 16px 16px 16px 0;
                    }
                }

                &:not(:first-child) {
                    & > div {
                        padding: 0 8px;
                    }
                }

                &:first-child {
                    padding-left: 10px;
                }

                &:last-child {
                    padding-right: 10px;
                }

                div {
                    &.select_wrapper {
                        display: flex;
                        justify-content: flex-start;
                        width: 100%;
                    }
                }
            }
        }
    }

    .filter_button {
        svg {
            color: var(--secondary-20)
        }
    }

    .empty_wrapper {
        padding: 16px;
        width: 100%;
        border-radius: 8px;
        border: 1px solid var(--secondary-80);
        background: #FFF;
    }
}
