
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  max-width: 300px;

  .title {
    margin-bottom: 8px;
  }

  .description {
    margin-bottom: 24px;

    @include mobile {
      margin-bottom: 26px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 32px;
    width: 100%;

    @include mobile {
      gap: 18px;
    }

    button {
      text-decoration: underline;
    }

    input {
      width: 32px;
      height: 40px;
      padding: 12px 10px;
    }
  }
}
