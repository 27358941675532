
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  max-width: 1236px;
  margin: 0 auto;
  padding: 88px 32px;
  display: flex;
  flex-direction: column;

  @include tablet {
    padding-top: 48px;
  }

  @include mobile {
    padding: 40px 24px;

    & > p {
      font-size: 20px !important;
    }
  }

  .title_wrapper {
    margin-bottom: 39px;

    .title {
      border-bottom: 1px solid var(--secondary-80);
      padding-bottom: 16px;
      margin-bottom: 16px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 42px;

    @include mobile {
      gap: 0;
    }

    .row {
      width: 100%;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        @include mobile {
          padding: 17px 0;
        }

        .switches_wrapper {
          display: flex;
          align-items: center;
          gap: 32px;
          width: 134px;

          @media (max-width: 550px) {
            gap: 4px;
            width: max-content;
          }

          .switch_wrapper {
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 8px;

            p {
              font-weight: 600;
            }
          }
        }
      }

      .row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .switches_wrapper {
          display: flex;
          width: 134px;
          justify-content: space-between;

          @media (max-width: 550px) {
            gap: 16px;
            width: max-content;
          }

          .switch_wrapper {
            display: flex;
            align-items: center;
            gap: 16px;
          }
        }
      }
    }
  }

  .save_button {
    align-self: center;
    margin-top: 24px;
  }
}
