
                    @import "src/styles/_mixins.scss";
                
.root {
  max-width: 210px;
  transition: .3s;
  position: relative;
  width: 100%;

  @include mobile {
    max-width: 100%;
  }

  .left_icon {
    padding: 12px;
    z-index: 1;
  }

  .close {

  }

  .right_icon {
    margin: 16px 12px 12px 24px;
    color: var(--secondary-30) !important;
    cursor: pointer;
  }

  &.full {
    max-width: 100%;
  }

  .label {
    transition: .3s;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: var(--secondary-20);
    z-index: 1;
    background: white;
    cursor: text;
  }

  .helper_text {
    transition: .3s;
    color: var(--neutral-50);
    margin-left: 6px;
    display: block;
    margin-top: 4px;
  }

  .error_text {
    transition: .3s;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: var(--error-40);
    margin-left: 16px;
    display: block;
  }

  .input_wrapper {
    width: 100%;
    position: relative;
    transition: .3s;
    display: flex;
    border-radius: 6px;
    border: 1px solid var(--neutral-60);
    background: var(--neutral-100);
    margin-top: 7px;

    input {
      outline: none;
      width: 90%;
      padding: 16px 16px 16px 0;
      border-radius: 4px;
      height: 56px;
      border: none;
      font-size: 16px;
      font-weight: 400;
      line-height: 100%;
      color: var(--secondary-20);

      &::placeholder {
        color: var(--neutral-50);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }

    button {
      cursor: default;
      margin-top: 2px;
      margin-bottom: -2px;
    }

    svg {
      color: var(--secondary-20);
    }
  }

  &.isOnclickIcon {
    .input_wrapper {
      button {
        cursor: pointer;
      }
    }
  }

  &.isLeftIcon {
    .label {
      left: 52px;

      &.isActive {
        left: 16px;
      }
    }
  }

  &:hover {
    .helper_text {
      color: var(--neutral-30)
    }

    .input_wrapper {
      border-color: var(--secondary-20);

      input {
        transition: none;
      }
    }
  }

  &:focus-within {
    .label {
      color: var(--primary-30);
    }

    &.isLeftIcon {
      .label {
        left: 16px;
      }
    }

    .input_wrapper {
      border-color: var(--primary-30);
      border-width: 2px;

      input {
        padding: 14.5px 15px 14.5px 0;

        &::placeholder {
          width: max-content;
        }
      }
    }
  }

  &.error {
    .label, .helper_text {
      color: var(--error-40);
    }
    .input_wrapper {
      border-color: var(--error-40);
      input {
        color: var(--error-40);
      }
      svg {
        color: var(--error-40);
      }
    }

    &:focus-within {
      .input_wrapper {
        input {
          &::placeholder {
            color: var(--error-40);
          }
        }
      }
    }
  }
}
