
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
  width: 100%;
  max-width: 368px;
  height: 235px;
  border-radius: 16px;
  border: 1px solid var(--secondary-80);
  background: var(--primary-100);

  .brand {
    width: 80px;
    align-self: flex-end;
  }

  .title {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    color: var(--neutral-40)
  }

  .description {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  .row {
    display: flex;
    align-self: center;
    justify-content: space-between;
    width: 100%;
  }
}