
                    @import "src/styles/_mixins.scss";
                
@import 'mixins';

:root {
    --primary-10: #9A6609;
    --primary-20: #BE8317;
    --primary-30: #DB9E2F;
    --primary-40: #EDB755;
    --primary-50: #F5CC82;
    --primary-60: #FFDFA6;
    --primary-70: #FFE9C0;
    --primary-80: #FDF1DC;
    --primary-90: #FFF8EC;
    --primary-100: #FFFDFA;

    --secondary-10: #1D1D1D;
    --secondary-20: #000;
    --secondary-30: #3D3D3D;
    --secondary-40: #4E4C47;
    --secondary-50: #666259;
    --secondary-60: #B5AE9F;
    --secondary-70: #D1CCBE;
    --secondary-80: #E1DCD0;
    --secondary-90: #F8F6F1;
    --secondary-100: #FDFDFD;

    --tertiary-10: #372505;
    --tertiary-20: #4A3309;
    --tertiary-30: #6B4706;
    --tertiary-40: #895609;
    --tertiary-50: #986820;
    --tertiary-60: #CBA366;
    --tertiary-70: #E2C292;
    --tertiary-80: #F4DCB8;
    --tertiary-90: #FFF4E4;
    --tertiary-100: #FFFBF5;

    --error-10: #410E0B;
    --error-20: #601410;
    --error-30: #8C1D18;
    --error-40: #B3261E;
    --error-50: #DC362E;
    --error-60: #E46962;
    --error-70: #EC928E;
    --error-80: #F2B8B5;
    --error-90: #F9DEDC;
    --error-100: #FFFBF5;

    --success-10: #064828;
    --success-20: #0A673A;
    --success-30: #0C884C;
    --success-40: #0F9D58;
    --success-50: #1DAF68;
    --success-60: #46BD83;
    --success-70: #69C598;
    --success-80: #ADE0C7;
    --success-90: #D1F0E1;
    --success-100: #F8FFFB;

    --neutral-10: #0C0C0B;
    --neutral-20: #201E1B;
    --neutral-30: #353535;
    --neutral-40: #66625B;
    --neutral-50: #84807B;
    --neutral-60: #A8A5A2;
    --neutral-70: #D3D1CF;
    --neutral-80: #F2F1EE;
    --neutral-90: #F8F8F8;
    --neutral-100: #FFF;
}

body {
    margin: 0;
    padding: 0;
}

* {
    box-sizing: border-box;
    font-family: Poppins, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
ul,
li {
    margin: 0;
    padding: 0;
    color: var(--secondary-20)
}

ul li {
    list-style-type: none;
}

svg {
    color: var(--secondary-30);
}

button, label,
a {
    cursor: pointer;
    border: none;
    background: none;
    padding: 0;
    transition: .3s;
}

.table_filter_dropdown {
    position: absolute;
    top: 44px;
    left: 0;
    width: max-content;
    border: 1px solid var(--neutral-70);
    background: var(--Neutral-N-90, #f8f8f8);
    z-index: 2;
    height: max-content;
    display: none;

    .item {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        color: #000;
        padding: 2px 16px;

        &:hover {
            background: var(--primary-90);
        }

        &.isActive {
            color: var(--primary-20);
            background: var(--primary-90);
        }
    }
}

input:disabled {
    background: white
}

.pac-container {
    z-index: 2;
    margin-top: 8px;
    top: 52px;
    width: 100%;
    border-radius: 4px;
    border: none;
    background: var(--neutral-100);
    display: flex;
    flex-direction: column;
    transition: .3s;
    overflow-y: auto !important;
    max-height: 200px;
    padding: 4px 0;

    .pac-item {
        cursor: pointer;
        border-top: none;
        padding: 8px 12px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: var(--secondary-20);
        transition: .3s;
        display: flex;
        align-items: center;
        text-align: left;

        .pac-icon {
            margin-top: 0;
        }

        &:hover {
            background: var(--primary-80);
        }
    }

    &:after {
        display: none;
    }
}

.iframe_card {
    .wrapper {
        width: 100%;
        max-width: 500px;
    }
}

.datepicker_wrapper {
    position: absolute;
    background: white;
    right: 0;
    z-index: 10;
    padding: 24px;
    height: auto;
    box-shadow: 0px 8px 20px 0px rgba(69, 74, 88, 0.15);
    border-radius: 8px;
    margin-top: 4px;

    .dropdown {
        border-radius: 16px;
        border: 1px solid var(--neutral-70);
        padding: 8px;
        width: 296px;
        table {
            border-spacing: 0;
        }

        @include mobile {
            width: 100%;
        }

        .mantine-DatePicker-levelsGroup > div {
            display: flex;
            width: 100%;
            flex-direction: column;
            align-content: center;
        }

        .mantine-DatePicker-day {
            width: 40px;
            height: 40px;
            position: relative;

            &[data-outside="true"], &[data-disabled="true"] {
                color: var(--neutral-60);
            }
        }

        .mantine-DatePicker-monthCell {
            padding: 0 !important;

            button {
                border-radius: 100px;
                font-size: 14px;
                font-weight: 400;

                &:hover {
                    background: var(--primary-90);
                    &[data-disabled="true"] {
                        background: none;
                    }
                }

                &[data-in-range="true"] {
                    &:before {
                        content: '';
                        z-index: -1;
                        position: absolute;
                        width: 40px;
                        height: 40px;
                        left: 0;
                        background: var(--primary-80);
                    }
                }

                &[data-last-in-range="true"] {
                    &:before {
                        content: '';
                        z-index: -1;
                        position: absolute;
                        width: 20px;
                        height: 40px;
                        left: 0;
                        background: var(--primary-80);
                    }
                }

                &[data-first-in-range="true"] {
                    &:before {
                        content: '';
                        z-index: -1;
                        position: absolute;
                        width: 20px;
                        height: 40px;
                        right: 0;
                        left: 20px;
                        background: var(--primary-80);
                    }
                }

                &[data-today="true"] {
                    color: var(--primary-30);
                    border: 1px solid var(--primary-30);
                    border-radius: 100px;
                }

                &[data-selected="true"] {
                    color: white;
                    background: var(--primary-30);
                    border-radius: 100px;
                }
            }
        }
        .mantine-DatePicker-weekday {
            padding: 0;
            width: 40px;
            height: 40px;
            font-weight: 600;
            font-size: 14px;

        }
        .mantine-DatePicker-calendarHeader {
            width: 100%;
        }
        .mantine-DatePicker-calendarHeaderLevel {
            font-size: 14px;
            font-weight: 600;
            height: 40px;

            &:hover {
                color: var(--primary-30);
            }
        }
        .mantine-DatePicker-monthsListCell button, .mantine-DatePicker-yearsListCell button {
            width: 100%;
            height: 40px;
            font-size: 14px;
            font-weight: 400;
            text-align: center;
            border-radius: 100px;
            &[data-disable="true"] {
                background: none;
            }

            &:hover {
                background: var(--primary-90);
                &[data-disabled="true"] {
                    background: none;
                }
            }
        }
        .mantine-DatePicker-calendarHeaderControl {
            width: 24px;
            height: 24px;
            border-radius: 100px;
            margin-top: 8px;
            transition: .3s;

            svg {
                transition: .3s;
                width: 100% !important;
                height: 100% !important;
            }

            &:hover {
                svg {
                    color: var(--primary-30);
                }
            }
        }
    }
}

.dropdown_dropdown {
    position: absolute !important;
    z-index: 2;
    margin-top: 8px;
    top: 20px;
    width: 200px;
    border-radius: 4px;
    border: none;
    background: var(--neutral-100);
    display: none;
    flex-direction: column;
    transition: .3s;
    padding: 0;
    overflow-y: auto !important;
    max-height: 350px;
    height: 0;
    right: 10px;

    &.isOpen {
        height: max-content;
        border: 1px solid var(--neutral-70);
        padding: 8px 0;
    }

    button {
        padding: 12px 16px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: var(--secondary-20);
        transition: .3s;
        text-align: left;
        display: flex;
        align-items: center;
        gap: 8px;

        &:hover {
            background: var(--neutral-90);
        }

        &.red {
            color: var(--error-50);
            &:hover {
                background: #FFF0EF;
            }
            svg {
                color: var(--error-50);
            }
        }
    }
}

.modal_dropdown_index {
    z-index: 1001;
}