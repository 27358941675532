
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    display: flex;
    min-height: 100vh;

    @include tablet {
        flex-direction: column;
        background: var(--primary-100);
    }

    .left {
        display: flex;
        flex-direction: column;
        gap: 60px;
        z-index: 3;
        padding: 64px 24px 32px calc((100% - 1425px) / 2);
        background: var(--primary-100);
        width: 100%;

        @media (max-width: 1457px) {
            margin-left: 0;
            padding: 72px 16px 32px;
            width: calc(100% - 316px);
        }

        @include tablet {
            width: 100%;
            padding: 32px 16px;
        }

        @include mobile {
            gap: 32px;
        }
    }

    .right {
        margin-right: calc((100% - 1425px) / 2);
        padding: 72px 0 32px 32px;
        width: 490px;

        @media (max-width: 1457px) {
            width: calc(316px - 32px);
            margin-left: 0;
            padding: 72px 16px 32px;
        }

        @include tablet {
            width: 100%;
            padding: 0 16px 32px;
        }
    }
}
