
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  padding: 16px;
  border-radius: 6px;
  background: var(--neutral-90);
  gap: 12px;

  .icon {
    color: var(--neutral-50);
  }

  .content {
    width: calc(100% - 36px);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    text-align: left;

    b {
      font-weight: 600;
    }
  }

  &.warning {
    background: #FFF0E3;

    svg {
      color: #FF7B00;
    }
  }

  &.error {
    background: #FFF0EF;

    svg {
      color: #DC362E;
    }
  }
}