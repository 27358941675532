
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .range_date_wrapper {
            max-width: 300px;
            width: 100%;
        }
    }

    .content {
        border-top: 1px solid var(--secondary-80);
        display: flex;
        flex-direction: column;
        gap: 21px;
        padding-top: 38px;

        .table_header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .left {
                display: flex;
                align-items: center;
                gap: 16px;
            }
        }
    }
}
