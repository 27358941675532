
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  width: 320px;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  align-items: center;

  @media (max-width: 420px) {
    width: 100%;
  }

  & > svg {
    color: var(--primary-40)
  }

  .title {
    margin-bottom: 8px;
    margin-top: 16px;
    text-align: center;
  }

  .inputs_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .input_wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    @include mobile {
      gap: 0;
    }
  }
}
