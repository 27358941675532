
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .steps {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: var(--primary-30);
    margin-bottom: 16px;
  }

  .title {
    text-align: center;
  }

  .resend_button {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    color: var(--primary-30)
  }

  .resend_text {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    color: var(--neutral-60);
  }

  .email_sent_desc {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: var(--neutral-40);
    max-width: 440px;
    text-align: center;
    margin-top: 16px;

    span {
      color: var(--neutral-40);
      font-weight: 600;
    }
  }

  .form {
    max-width: 440px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin-top: 40px;

    @include mobile {
      margin-top: 32px;
    }

    .agree {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;

      p {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        color: var(--neutral-40);

        a {
          color: var(--primary-30);
          text-decoration: none;
          border-bottom: none;
        }
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    margin-top: 24px;

    @include mobile {
      margin-top: 16px;
    }

    .back {
      color: var(--neutral-40);

      svg {
        color: var(--neutral-40);
      }
    }
  }
}
