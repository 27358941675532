
                    @import "src/styles/_mixins.scss";
                
.root {
    border-radius: 100px;
    font-size: 16px;
    font-weight: 500;
    line-height: 125%;

    @include mobile {
        font-size: 14px !important;
    }

    &.outlined {
        border: 1px solid var(--primary-30);
        background: var(--neutral-100);
        color: var(--primary-30);
        &.isActive {
            color: var(--neutral-100);
            background: var(--primary-30);
        }
    }
    &.outlined-dark {
        border: 1px solid var(--secondary-20);
        background: var(--neutral-100);
        color: var(--secondary-20);
        &.isActive {
            color: var(--primary-40);
            background: var(--secondary-20);
        }
    }

    &:hover {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    }

    &:active {
        box-shadow: none;
    }
}
