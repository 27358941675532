
                    @import "src/styles/_mixins.scss";
                
.root {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: white;
    text-decoration: none;
    transition: 0.3s;
    padding: 8px 20px;

    @include tablet {
        padding: 4px 12px;
    }

    &:hover {
        border-radius: 30px;
        background: var(--secondary-30);
    }

    &.isActive {
        color: var(--primary-30);
        font-weight: 700;
    }

    @include tablet {
        font-size: 18px;
    }
}
