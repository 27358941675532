
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  .header {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    border-bottom: 1px solid var(--secondary-80);
    padding-bottom: 24px;
    width: 100%;

    .back {
      position: absolute;
      left: 0;
    }
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding-top: 40px;
    max-width: 664px;

    .description {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      text-align: center;
      max-width: 538px;
      width: 100%;
    }

    .agreement {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;

      .description {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        text-align: center;
        max-width: 538px;
        width: 100%;
      }

      .form_row {
        display: flex;
        align-items: flex-start;
        gap: 24px;

        .checkbox {
          width: 40px;
        }

        .radio {
          align-self: center;
        }
      }

      .send_disclaimer {
        width: calc(100% - 64px);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        span {
          font-weight: 600;
        }
        a {
          color: var(--primary-30);
          font-size: 16px;
          font-style: normal;
          text-decoration: none;
          line-height: 150%;
        }
      }
    }

    .email_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .left {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        color: var(--neutral-40);
      }

      .right {
        display: flex;
        align-items: center;
        gap: 16px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }
  }
}