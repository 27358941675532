
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow-x: auto;
  scrollbar-width: none;

  .tab {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    min-width: 220px;
    cursor: pointer;
    transition: .3s;
    border-bottom: 1px solid var(--secondary-80);

    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 150%;
      white-space: nowrap;
      color: var(--neutral-50);
    }

    &.isActive {
      border-bottom: 1px solid var(--secondary-20);
      p {
        color: var(--secondary-20);
        font-weight: 600;
      }
    }
  }
}
