
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  width: 416px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  max-height: calc(80vh - 64px);
  overflow-x: auto;

  @include mobile {
    width: 100%;
  }

  & > .title {
    padding: 8px 0 16px 0;
    text-align: center;
    border-bottom: 1px solid var(--secondary-80);
  }

  .note {
    font-size: 12px;
    line-height: 120%;

    svg {
      display: inline;
      margin-bottom: -4px;
      color: var(--primary-40)
    }

    span {
      font-weight: 700;
    }
  }

  .amount_wrapper {
    display: flex;
    flex-direction: column;

    .amount_title {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }

    .amount {
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
  }

  .types_wrapper {
    display: flex;
    gap: 16px;
    align-items: stretch;
    width: 100%;

    @include mobile {
      flex-direction: column;
    }

    .type {
      border-radius: 8px;
      border: 1px solid var(--secondary-80);
      background: var(--secondary-100);
      padding: 16px 8px 60px 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      cursor: pointer;
      position: relative;

      @include mobile {
        width: 100%;
      }

      p {
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
      }

      .title {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
      }

      .radio {
        position: absolute;
        bottom: 8px;
      }

      &.isActive {
        background: var(--primary-90);
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    gap: 16px;
    margin-top: 24px;
  }

  .summary {
    border-radius: 8px;
    border: 1px solid var(--secondary-80);
    background: var(--secondary-100);
    padding: 16px;
    display: flex;
    flex-direction: column;
    width: 100%;

    .title {
      font-size: 20px;
      font-weight: 600;
      line-height: 150%;
      margin-bottom: 16px;
    }

    .summary_line {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      & > div:first-child {
        width: 140px;
      }

      & > div:last-child {
        width: calc(100% - 140px);
      }

      @include mobile {
        flex-direction: column;
        align-items: start;
        gap: 8px;
      }
    }
  }

  .success {
    text-align: center;
    img {
      max-width: 350px;
      margin-top: -50px;
      margin-bottom: -50px;
    }

    .title {
      margin-bottom: 16px;
      color: var(--primary-30);
      text-align: center;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
    }
  }
}