
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .steps {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: var(--primary-30);
    margin-bottom: 16px;
  }

  .title {
    margin-bottom: 40px;
    text-align: center;
  }

  .description {
    margin-bottom: 57px;
    max-width: 572px;
    text-align: center;

    span {
      font-weight: 600;
    }
  }

  .form {
    max-width: 440px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .actions {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    margin-top: 20px;

    .back {
      color: var(--neutral-40);

      svg {
        color: var(--neutral-40);
      }
    }
  }
}
